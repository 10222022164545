import React, { useEffect, useRef, useState } from 'react';
import { select, scaleBand, axisBottom, scaleLinear, axisLeft, scaleOrdinal, stack, stackOrderAscending, max } from "d3";

const useResizeObserver = ref => {
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach(entry => {
                setDimensions(entry.contentRect);
            })
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return dimensions;
};

    const BarChartComponent=(props)=> {
    // console.log("===>BarChartComponent props<===",props);
    const data = props.barChartdata;
    const properties = props.barChartProperties;
    // console.log(properties);
    // console.log(data);
    let dataArray = [];
    if (data !== undefined && data.length > 0) {
        for (let index = 1; index < data[0].length; index++) {
            let obj = {};
            for (let index1 = 0; index1 < data.length; index1++) {
              obj[data[index1][0]] = data[index1][index];
            }
            dataArray.push(obj);
        }
    };
    // console.log("-->Array<--",dataArray.slice(0, 11))
    let keys = [];
    if (data !== undefined && data.length > 0) {
        for (let index = 1; index < data.length; index++) {
            keys.push(data[index][0]);
        } 
    };
    // console.log(dataArray);
    // console.log(keys);

    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    useEffect(() => {
        const svg = select(svgRef.current);

        const stackGenerator = stack()
            .keys(keys)
            .order(stackOrderAscending);
    
        const layers = stackGenerator(dataArray.slice(0, 11));
    
        const extent = [0,max(layers, layer => max(layer, sequence => sequence[1] - sequence[0]))];

        if (!dimensions) return;
    
        const xScale = scaleBand()
            .domain(props.fullScreenValue === false?dataArray.slice(0, 11).map(d => d.x):dataArray.map(d => d.x))
            .range([50, dimensions.width/1])
            .padding(0.2);
    
        const yScale = scaleLinear()
            .domain(extent)
            .range([dimensions.height/1.4, 0]);
        
        const color = scaleOrdinal()
            .domain(keys)
            .range([properties.currYearColor, properties.prevYearColor]);
    
        const xAxis = axisBottom(xScale);
    
        const yAxis = axisLeft(yScale).tickFormat((d) => { if (d / 1000000000 >= 1) {
            return +(d / 1000000000).toFixed(1) + ' B'
          } else if (d / 1000000 >= 1) {
            return +(d / 1000000).toFixed(1) + ' M'
          } else if (d / 1000 >= 1) {
            return +(d / 1000).toFixed(1) + ' K'
          } else {
            return d
        }});
    
        svg
            .select(".x-axis")
            .style("transform", `translateY(${dimensions.height/1.4}px)`)
            .call(xAxis)
            .selectAll("text")
            .attr("transform", "translate(10)rotate(-20)")
            .style("text-anchor", "end")
            .style("font-size", 10)
            .style("fill", "black");
    
        svg
            .select(".y-axis")
            .style("transform", `translateX(${50}px)`)
            .call(yAxis);
    
        svg
            .selectAll(".layer")
            .data(layers)
            .join("g")
            .attr("class", "layer")
            .attr("id", layer => {return layer.index})
            .attr("fill", layer => {
                return color(layer.key);
            })
            .selectAll("rect")
            .data(layer => layer)
            .join("rect")
            .attr("x", (sequence, i, j) => {
                return xScale(sequence.data.x) + xScale.bandwidth() / 2 * parseInt(j[i].parentNode.id);
            })
            .attr("width", xScale.bandwidth()/ 2)
            .attr('y', sequence => dimensions.height/1.4 - (yScale(sequence[0]) - yScale(sequence[1])))
            .attr("height", sequence => yScale(sequence[0]) - yScale(sequence[1]));
        
    }, [dimensions, keys, dataArray]);

    return (
        <div ref={wrapperRef} style={{
            height: props.fullScreenValue === true ? "75vh" : props.height === undefined || props.height === null ? "35vh" : `${props.height-5}vh`
        }}>
            <svg ref={svgRef} style={{
                background: `${properties.bgcolor}`,
                overflow: 'visible',
                display: 'block',
                width: '100%',
                height: props.fullScreenValue === true ? "75vh" : props.height === undefined || props.height === null ? "35vh" : `${props.height-5}vh`
            }}>
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
        </div>
    )
}

export default BarChartComponent
