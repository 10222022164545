import React, { useState, Fragment } from "react";
import UserHeader from "./UserHeader";
import UserLine from "./UserLines";

const ListWindow = () => {
  const [searchKey, setSearchKey] = useState();
  const [lastRefreshed, setLastRefreshed] = useState();
  return (
    <Fragment>
      <UserHeader setSearchKey={setSearchKey} setLastRefreshed={setLastRefreshed} />
      <UserLine searchKey={searchKey} lastRefreshed={lastRefreshed}/>
    </Fragment>
  );
};

export default ListWindow;
