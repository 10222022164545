import React, { useState, Fragment } from "react";
import RoleHeader from "./RoleHeader";
import RoleLine from "./RoleLine";

const ListWindow = () => {
  const [searchKey, setSearchKey] = useState();
  const [lastRefreshed, setLastRefreshed] = useState();
  return (
    <Fragment>
      <RoleHeader setSearchKey={setSearchKey} setLastRefreshed={setLastRefreshed} />
      <RoleLine searchKey={searchKey} lastRefreshed={lastRefreshed}/>
    </Fragment>
  );
};

export default ListWindow;
