import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Row, Col, Popover, Image, Button, Divider, Form, Select, Card, message, Menu, Tooltip } from "antd";
import { useGlobalContext } from "../../lib/storage";
import { getAdminMenuList, get360MenuList, getFavouritesMenuList, removeFavouriteMenu, createFavouriteMenu } from "../../services/generic";
import Theme from "../../constants/UIServer.json";
import Logo from "../../assets/images/cwsuitewhite.png";
import Toggle from "../../assets/images/Menuicon.svg";
import Heart from "../../assets/images/Heart_outline_white.svg";
import Report from "../../assets/images/report.svg";
import Dashboards from "../../assets/images/dashboard.svg";
import Settings from "../../assets/images/settings.svg";
import Profile from "../../assets/images/blankImage.png";
import Yellowpin from "../../assets/images/Yellowpin.svg";

import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";

const { Option, OptGroup } = Select;
const { SubMenu } = Menu;

const NavBar = (props) => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const history = useHistory();
  const Themes = globalStore.userData.CW360_V2_UI === undefined ? Theme : JSON.parse(globalStore.userData.CW360_V2_UI);
  let usersData = JSON.parse(localStorage.getItem("userData"));
  const { setMenuToggle } = props;
  const menuList = globalStore.sideMenuData;
  const [menuData, setMenuData] = useState([]);
  const [favouriteMenuData, setFavouriteMenuData] = useState([]);

  const data = {
    name: "CW Solutions",
    Email: "cw@solutions",
    Phone: 9876543210,
  };

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 4,
    sm: 12,
    md: 12,
  };

  const responsiveSearch = {
    xxl: 6,
    xl: 6,
    lg: 6,
    xs: 20,
    sm: 12,
    md: 8,
  };

  useEffect(() => {
    getFaviouritesMenus();
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }
  }, []);

  const logout = () => {
    setGlobalStore({ authTokens: undefined });
    localStorage.clear();
    history.push("/login");
  };

  const goToHome = async () => {
    let menuDataResponse = JSON.parse(localStorage.getItem("sideMenuData"));
    if (menuDataResponse === null) {
      menuDataResponse = await get360MenuList(usersData.role_id);
      localStorage.setItem("sideMenuData", JSON.stringify(menuDataResponse));
    }
    setGlobalStore({ sideMenuData: menuDataResponse });
    history.replace("/");
  };

  const getAdminMenus = async () => {
    let menuDataResponse = JSON.parse(localStorage.getItem("adminMenuData"));
    if (menuDataResponse === null) {
      menuDataResponse = await getAdminMenuList(usersData.role_id);
      localStorage.setItem("adminMenuData", JSON.stringify(menuDataResponse));
    }
    history.replace("/");
    setGlobalStore({ sideMenuData: menuDataResponse });
  };

  const getFaviouritesMenus = async () => {
    const favouritesMenuDataResponse = await getFavouritesMenuList();
    setFavouriteMenuData(favouritesMenuDataResponse);
  };

  const deleteFavourites = async (id) => {
    const removeFavouriteMenuResponse = await removeFavouriteMenu(id);
    message.success(removeFavouriteMenuResponse);
    getFaviouritesMenus();
  };

  const selectMenu = async (value, data) => {
    const valueData = value.split("@");
    const type = valueData[1];
    const addFavouritesResponse = await createFavouriteMenu(data.key, data.children, data.url, type, globalStore.userData.cs_client_id);
    message.success(addFavouritesResponse);
    getFaviouritesMenus();
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const getFilteredMenuData = (menuParam, filterKey) => {
    return menuParam.filter((menu) => {
      if (menu.children) {
        const subChildren = menu.children.filter((c) => c.type === filterKey);
        return subChildren.length > 0;
      } else {
        return false;
      }
    });
  };

  const getFilteredSubMenuData = (menuParam, filterKey) => {
    return menuParam.filter((c) => c.type === filterKey);
  };

  const content = (
    <Card style={Themes.navBar.logoutCardStyle}>
      <Row>
        <Col span={6}>
          <Image alt="profile" src={Profile} style={Themes.navBar.profileIcon} />
        </Col>
        <Col span={12} offset={6}>
          <Row>
            <Col span={24} style={Themes.navBar.profileName}>
              {data.name}
            </Col>
            <Col span={24} style={Themes.navBar.profileInfo}>
              {data.Email === "null" ? "" : data.Email}
            </Col>
            <Col span={24} style={Themes.navBar.profileInfo}>
              {data.Phone === "null" ? "" : data.Phone}
            </Col>
          </Row>
        </Col>
        <Divider style={Themes.navBar.dividerOne} />
        <Col span={24}>
          <Form layout="vertical">
            <Row style={{ margin: "-4px" }}>
              <Col span={24}>
                <Form.Item label="Roles" style={Themes.navBar.roles}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    defaultValue="CW Solutions"
                  >
                    <Option value="CW Solutions">CW Solutions</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Business Unit" style={Themes.navBar.businessUnit}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    defaultValue="cw@solutions"
                  >
                    <Option value="cw@solutions">cw@solutions</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} style={{ textAlign: "right" }}>
                <Form.Item style={Themes.navBar.businessUnit}>
                  <Button style={Themes.navBar.cancelButton}>Cancel</Button>
                  <Button type="primary" htmlType="submit" style={Themes.navBar.applyButton}>
                    Apply
                  </Button>
                </Form.Item>
              </Col>
              <Divider style={Themes.navBar.dividerTwo} />
              <Button type="link" onClick={logout} style={Themes.navBar.logoutButton}>
                <i className="fa fa-sign-out" style={Themes.navBar.logoutButton.logoutIcon} aria-hidden="true"></i>{" "}
                <span style={Themes.navBar.logoutButton.logoutText}>Logout</span>
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );

  const favMenuContent = (
    <div style={{ width: "18em" }}>
      <Select
        showSearch
        style={{ width: "100%", paddingRight: "8px" }}
        suffixIcon={<i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />}
        onSelect={selectMenu}
        value={null}
        placeholder={
          <Row>
            <Col span={4}>
              <i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />
            </Col>
            <Col span={20}>Search...</Col>
          </Row>
        }
        showArrow={false}
        className="search-arrow placeHolder"
      >
        {menuData.map((menu, index) => (
          <OptGroup key={`${menu.title}-${index}`} label={menu.title}>
            {menu.children
              ? menu.children.map((subMenuItem) => (
                  <Option key={subMenuItem.id} url={subMenuItem.url} value={`${subMenuItem.title}@${subMenuItem.type}`}>
                    {subMenuItem.title}
                  </Option>
                ))
              : null}
          </OptGroup>
        ))}
      </Select>
      <p />
      <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
        {favouriteMenuData !== null
          ? favouriteMenuData.map((data) => {
              return (
                <li key={data.menuId} style={{ paddingTop: "2px", paddingBottom: "2px", cursor: "pointer" }}>
                  <Row gutter={16}>
                    <Col span={20}>
                      <span
                        onClick={() => {
                          if (data.type === "Report") {
                            history.push(`/reports/report/${data.menuId}`);
                          } else if (data.type === "Dashboard") {
                            history.push(`/analytics/dashboard/${data.menuId}`);
                          } else if (data.type === "Generic") {
                            history.push(`/window/list/${data.menuId}`);
                          } else if (data.type === "Custom") {
                            history.push(`/others/window/${data.menuId}`);
                          } else {
                            message.warning("Not Available");
                          }
                        }}
                      >
                        {data.menuName}
                      </span>
                    </Col>
                    <Col span={4}>
                      <img alt="pin" src={Yellowpin} onClick={() => deleteFavourites(data.id)} />
                    </Col>
                  </Row>
                </li>
              );
            })
          : ""}
      </ul>
    </div>
  );

  const reportMenuContent = (
    <div style={{ width: "18em", height: "220px" }}>
      <Scrollbars
        style={{
          height: "220px",
        }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbSize={90}
        renderView={renderView}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
      >
        <Menu theme="light" mode="inline">
          {getFilteredMenuData(menuData, "Report").map((menu) => (
            <SubMenu
              key={`${menu.key}`}
              icon={
                <span className={menu.icon} style={{ color: "#666666" }}>
                  &ensp;
                </span>
              }
              title={`${menu.title}`}
            >
              {menu.children
                ? getFilteredSubMenuData(menu.children, "Report").map((subMenuItem, index) => (
                    <Menu.Item key={`${subMenuItem.key}-${index}`} onClick={() => history.push(`/reports/report/${subMenuItem.id}`)} title={subMenuItem.title}>
                      {subMenuItem.title}
                    </Menu.Item>
                  ))
                : null}
            </SubMenu>
          ))}
        </Menu>
      </Scrollbars>
    </div>
  );

  const dashboardMenuContent = (
    <div style={{ width: "18em", height: "220px" }}>
      <Scrollbars
        style={{
          height: "220px",
        }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbSize={90}
        renderView={renderView}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
      >
        <Menu theme="light" mode="inline">
          {getFilteredMenuData(menuData, "Dashboard").map((menu) => (
            <SubMenu
              key={`${menu.key}`}
              icon={
                <span className={menu.icon} style={{ color: "#666666" }}>
                  &ensp;
                </span>
              }
              title={`${menu.title}`}
            >
              {menu.children
                ? getFilteredSubMenuData(menu.children, "Dashboard").map((subMenuItem, index) => (
                    <Menu.Item key={`${subMenuItem.key}-${index}`} onClick={() => history.push(`/analytics/dashboard/${subMenuItem.id}`)} title={subMenuItem.title}>
                      {subMenuItem.title}
                    </Menu.Item>
                  ))
                : null}
            </SubMenu>
          ))}
        </Menu>
      </Scrollbars>
    </div>
  );

  return (
    <div>
      <Row justify="space-between">
        <Col {...responsiveDesignForColumn}>
          <Row>
            <Col {...responsiveSearch} style={{ cursor: "pointer" }}>
              <Image onClick={goToHome} src={Logo} preview={false} style={Themes.navBar.logoStyles} />
            </Col>
            <Col {...responsiveSearch}>
              <Image src={Toggle} onClick={() => setMenuToggle((toggle) => !toggle)} preview={false} style={Themes.navBar.toggleStyles} />
            </Col>
            <Col {...responsiveDesignForColumn} />
          </Row>
        </Col>
        <Col {...responsiveSearch}>
          <Row>
            <Col span={4} />
            <Col span={3} style={{ textAlign: "center" }}>
              <Popover
                title={<p style={{ paddingBottom: "0px", marginBottom: "0px", textAlign: "center" }}>Favourites</p>}
                placement="bottom"
                style={{ marginLeft: "120px" }}
                content={favMenuContent}
                trigger="hover"
              >
                {" "}
                <Tooltip placement="left" title="Heart">
                  <Image src={Heart} preview={false} style={Themes.navBar.navbarIcons} />{" "}
                </Tooltip>
              </Popover>
            </Col>
            <Col span={3} style={{ textAlign: "center" }}>
              <Popover
                title={<p style={{ paddingBottom: "0px", marginBottom: "0px", textAlign: "center" }}>Reports</p>}
                placement="bottom"
                style={{ marginLeft: "120px" }}
                content={reportMenuContent}
                trigger="hover"
              >
                {" "}
                <Tooltip title="Report">
                  <Image src={Report} preview={false} style={Themes.navBar.navbarIcons} />{" "}
                </Tooltip>
              </Popover>
            </Col>
            <Col span={3} style={{ textAlign: "center" }}>
              <Popover
                title={<p style={{ paddingBottom: "0px", marginBottom: "0px", textAlign: "center" }}>Dashboards</p>}
                placement="bottom"
                style={{ marginLeft: "120px" }}
                content={dashboardMenuContent}
                trigger="hover"
              >
                {" "}
                <Tooltip title="Dashboards">
                  <Image src={Dashboards} preview={false} style={Themes.navBar.navbarDashboardIcon} />{" "}
                </Tooltip>
              </Popover>
            </Col>
            <Col span={3} style={{ textAlign: "center", cursor: "pointer" }}>
              {" "}
              <Tooltip title="Settings">
                <Image src={Settings} onClick={getAdminMenus} preview={false} style={Themes.navBar.navbarSettingIcon} />{" "}
              </Tooltip>
            </Col>
            <Col span={8} style={{ textAlign: "center", marginTop: "-4%" }}>
              <Popover content={content} trigger="click" placement="topRight">
                <span style={{ fontWeight: "bold", marginTop: "2px", color: "white", whiteSpace: "nowrap", fontSize: "12px" }}> {data.name} </span>{" "}
                <i className="fas fa-caret-circle-down" size="2x" /> <i className="downArrow" />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NavBar;
