import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, AutoComplete, Input, Dropdown, Menu, Checkbox, message, Alert, Modal, Form, notification, Collapse, Spin, Tooltip } from "antd";
import { useWindowContext, useGlobalContext } from "../../lib/storage";
import Theme from "../../constants/UIServer.json";
// import Options from "../../assets/images/Options_icon.svg";
import TreeView from "../../assets/images/tree_View.svg";
import Export from "../../assets/images/export.svg";
import QuickAdd from "../../assets/images/Quickadd.svg";
import DownArrow from "../../assets/images/arrow-drop-down.svg";
import Reset from "../../assets/images/reset.svg";
import Summary from "../../assets/images/summary.svg";
import Selection from "../../assets/images/selection.svg";
// import Setting from "../../assets/images/settingIcon.svg";
import ShowAndHide from "../../assets/images/showandHide.svg";
import Repeat from "../../assets/images/repeat.svg";
// import Import from "../../assets/images/import.svg";
import Print from "../../assets/images/print.svg";
import Filter from "../../assets/images/filter.svg";
import Edit from "../../assets/images/edit.svg";
import Trash from "../../assets/images/trash.svg";
import RecordForm from "../window/RecordForm";
import dayjs from "dayjs";
import { Scrollbars } from "react-custom-scrollbars";
import { LoadingOutlined } from "@ant-design/icons";
import { FieldReference } from "../../lib/fieldReference";
import { deleteTabRecord, getWindowInitializationData, upsertTabData } from "../../services/generic";
import "antd/dist/antd.css";

const { Panel } = Collapse;

const ListWindowHeader = (props) => {
  const {
    setSearchKey,
    setLastRefreshed,
    treeView,
    columns,
    resetFilters,
    takeSummary,
    takeHideAndshowData,
    hideAndShowTitles,
    kanbanCards,
    selectedRowKeys,
    setWindowLoading,
  } = props;
  const history = useHistory();
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI === undefined ? Theme : JSON.parse(globalStore.userData.CW360_V2_UI);
  const { windowStore, setWindowStore } = useWindowContext();
  const { windowDefinition } = windowStore;
  const [searchInput, setSearchInput] = useState("");
  const [headerTabData, setHeaderTabData] = useState({});
  const [hideAndShowData, setHideAndShowData] = useState([]);
  const [headerFieldGroups, setHeaderFieldGroups] = useState({});
  const [visible, setVisible] = useState(false);
  const [summaryVisible, setSummaryVisible] = useState(false);
  const [checkedSummaryItems, setCheckedSummaryItems] = useState(() => {
    if (windowDefinition.summaryItems === undefined || windowDefinition.summaryItems === []) {
      return [];
    } else {
      return windowDefinition.summaryItems;
    }
  });
  const [checkBox, setCheckBox] = useState(false);
  const [summaryMenuItems, setSummaryMenuItems] = useState([]);

  const getSearchData = (e) => {
    const searchValue = e.target.value;
    setSearchInput(searchValue);
    setSearchKey(searchValue);
  };

  const refreshData = () => {
    setLastRefreshed(new Date());
  };

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 12,
    sm: 12,
    md: 12,
  };
  const responsiveButton = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 24,
    sm: 24,
    md: 12,
  };

  const responsiveSearch = {
    xxl: 6,
    xl: 6,
    lg: 6,
    xs: 24,
    sm: 12,
    md: 6,
  };

  useEffect(() => {
    if (windowDefinition.tabs) {
      const headerTab = windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
      headerTab.fields.sort((a, b) => {
        const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
        const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
        return (x != null ? x : Infinity) - (y != null ? y : Infinity);
      });
      setHeaderTabData(headerTab);
      let hideAndShowTitles = [];
      for (let index1 = 0; index1 < headerTab.fields.length; index1++) {
        if (headerTab.fields[index1].nt_base_reference_id !== FieldReference.Button && headerTab.fields[index1].isdisplayed === "Y" && headerTab.fields[index1].isactive === "Y") {
          hideAndShowTitles.push({
            title: headerTab.fields[index1].name,
            checked: headerTab.fields[index1].showinrelation === "Y" ? true : false,
          });
        }
      }

      const fieldGroupsList = {};
      headerTab.fields.forEach((element) => {
        if (element.fieldgroup_name !== undefined && element.nt_base_reference_id !== "28") {
          fieldGroupsList[element.fieldgroup_name] = fieldGroupsList[element.fieldgroup_name] || [];
          fieldGroupsList[element.fieldgroup_name].push(element);
        }
      });
      setHeaderFieldGroups(fieldGroupsList);

      takeHideAndshowData(hideAndShowTitles);
      setHideAndShowData(hideAndShowTitles);
    }
  }, [checkBox, hideAndShowTitles]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const onChange = (e, i) => {
    const windowDef = { ...windowDefinition };
    if (windowDef.tabs) {
      const headerTab = windowDef.tabs[windowDef.tabs.findIndex((tab) => tab.tablevel === "0")];
      headerTab.fields.sort((a, b) => {
        const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
        const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
        return (x != null ? x : Infinity) - (y != null ? y : Infinity);
      });
      for (let index1 = 0; index1 < headerTab.fields.length; index1++) {
        if (headerTab.fields[index1].name === e.target.id) {
          if (e.target.checked) {
            headerTab.fields[index1].showinrelation = "Y";
          } else {
            headerTab.fields[index1].showinrelation = "N";
          }
        }
      }
    }
    setWindowStore({ windowDefinition: windowDef });
    setCheckBox(!checkBox);
  };

  const menu = () => {
    return (
      <Menu
        key="1"
        style={{
          overflowY: "scroll",
          maxHeight: "15rem",
        }}
      >
        {hideAndShowData.map((item, index) => {
          return (
            <Menu.Item key={index}>
              <Checkbox key={index} id={item.title} onChange={(e) => onChange(e, index)} checked={item.checked}>
                {item.title}
              </Checkbox>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const handleSummaryVisibleChange = (flag) => {
    setSummaryVisible(flag);
  };

  const handleSummary = (e) => {
    const windowDef = { ...windowDefinition };
    if (windowDef) {
      if (e.target.id === "SUM") {
        if (e.target.checked) {
          windowDef.sum = true;
        } else {
          windowDef.sum = false;
        }
      }
      if (e.target.id === "COUNT") {
        if (e.target.checked) {
          windowDef.count = true;
        } else {
          windowDef.count = false;
        }
      }
      if (e.target.id === "MIN") {
        if (e.target.checked) {
          windowDef.min = true;
        } else {
          windowDef.min = false;
        }
      }
      if (e.target.id === "MAX") {
        if (e.target.checked) {
          windowDef.max = true;
        } else {
          windowDef.max = false;
        }
      }
      if (e.target.id === "AVG") {
        if (e.target.checked) {
          windowDef.avg = true;
        } else {
          windowDef.avg = false;
        }
      }
    }
    let fieldIds = [];
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].baseReferenceId === "22") {
        fieldIds.push(columns[i].dataIndex.replace("_iden", ""));
      }
    }
    let summaryItems = checkedSummaryItems;
    if (e.target.checked) {
      summaryItems.push(e.target.id);
    } else {
      summaryItems = summaryItems.filter((id) => {
        return id !== e.target.id;
      });
    }
    setCheckedSummaryItems(summaryItems);
    if (windowDef) {
      windowDef.summaryItems = summaryItems;
    }
    setWindowStore({ windowDefinition: windowDef });
    let summaryData = [];
    if (summaryItems.length > 0) {
      for (let index = 0; index < summaryItems.length; index++) {
        summaryData.push({
          key: summaryItems[index],
        });
      }
    }
    if (fieldIds.length > 0) {
      if (summaryData.length > 0) {
        for (let index = 0; index < summaryData.length; index++) {
          Object.assign(summaryData[index], { fields: fieldIds });
        }
      }
      const summary = {};
      summaryData.map((data) => {
        return (summary[data.key] = data.fields);
      });
      takeSummary(summary);
    }
  };

  useEffect(() => {
    const summaryMenu = [
      {
        title: "Total",
        id: "SUM",
        checked: windowDefinition.sum === undefined || windowDefinition.sum === false ? false : true,
      },
      {
        title: "Count",
        id: "COUNT",
        checked: windowDefinition.count === undefined || windowDefinition.count === false ? false : true,
      },
      {
        title: "Min",
        id: "MIN",
        checked: windowDefinition.min === undefined || windowDefinition.min === false ? false : true,
      },
      {
        title: "Max",
        id: "MAX",
        checked: windowDefinition.max === undefined || windowDefinition.max === false ? false : true,
      },
      {
        title: "Average",
        id: "AVG",
        checked: windowDefinition.avg === undefined || windowDefinition.avg === false ? false : true,
      },
    ];
    setSummaryMenuItems(summaryMenu);
  }, [windowDefinition]);

  const summaryMenu = () => {
    return (
      <Menu key="1">
        {summaryMenuItems.map((item, index) => {
          return (
            <Menu.Item key={index}>
              <Checkbox key={index} id={item.id} onChange={handleSummary} checked={item.checked}>
                {item.title}
              </Checkbox>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const [isAlertActive, setIsAlertActive] = useState(false);
  const [errorMessageDetails, setErrorMessageDetails] = useState();
  const deleteRecords = async () => {
    setWindowLoading(true);
    const recordArray = [];
    selectedRowKeys.map((recordKey) => {
      return recordArray.push(recordKey.recordId);
    });
    const deleteResponse = await deleteTabRecord(headerTabData.ad_tab_id, recordArray);
    if (deleteResponse.messageCode === "200") {
      setWindowLoading(false);
      message.success(deleteResponse.message);
      refreshData();
    } else {
      setWindowLoading(false);
      setErrorMessageDetails(deleteResponse);
      setIsAlertActive(true);
    }
  };

  const editRecord = () => {
    history.push(`/window/${windowDefinition.ad_window_id}/${selectedRowKeys[0].recordId}`);
  };

  const displayErrorDetails = () => {
    if (errorMessageDetails) {
      Modal.error({
        title: errorMessageDetails.title,
        content: errorMessageDetails.message,
      });
    }
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const [headerRecordData, setHeaderRecordData] = useState({});
  const [visibleQuickAddModal, setVisibleQuickAddModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [form] = Form.useForm();
  const showQuickAdd = async () => {
    const headerRecordData = await getWindowInitializationData(headerTabData.ad_tab_id);
    setHeaderRecordData(headerRecordData);
    setVisibleQuickAddModal(true);
  };

  const handleSave = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((error) => {
        console.error(JSON.stringify(error, null, 2));
      });
  };

  const onFinish = (values) => {
    setFormLoading(true);
    Object.entries(values).map(([key, value]) => {
      if (value === true) {
        values[key] = "Y";
      }
      if (value === false) {
        values[key] = "N";
      }
      if (typeof value === "string" && value.search("<p>") > -1) {
        let stripedHtml = value.replaceAll("<p><br></p>", "");
        stripedHtml = stripedHtml.replaceAll("<p>", "<div>");
        stripedHtml = stripedHtml.replaceAll("</p>", "</div>");
        values[key] = stripedHtml;
      }
      if (typeof value === "number") {
        values[key] = `${value}`;
      }
      if (dayjs.isDayjs(value)) {
        values[key] = `${value.format("YYYY-MM-DD HH:mm:ss")}`;
      }
      if (value === "") {
        values[key] = null;
      }
      if (value === undefined) {
        values[key] = null;
      }
      return null;
    });

    Object.entries(values).map(() => {
      Object.entries(headerRecordData).map(([headerKey, headerValue]) => {
        if (values[headerKey] === undefined) {
          if (headerKey.search("_iden") === -1) {
            values[headerKey] = headerValue;
          }
        }
        return null;
      });
      return null;
    });

    const stringifiedFields = JSON.stringify(values);
    const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
    const stringRequest = JSON.stringify(updatedStrings);

    upsertTabData(headerTabData.ad_tab_id, "NEW_RECORD", stringRequest)
      .then((upsertResponse) => {
        if (upsertResponse.data.data.upsertTab.status === "200") {
          message.success(`${upsertResponse.data.data.upsertTab.message}`);
          setVisibleQuickAddModal(false);
          setLastRefreshed(new Date());
        } else {
          console.error(JSON.stringify(upsertResponse, null, 2));
          notification.error({
            message: "Error Processing Operation",
            description: (
              <Collapse ghost>
                <Panel header="Details" key="1">
                  {upsertResponse.data.data.upsertTab.message}
                </Panel>
              </Collapse>
            ),
          });
        }
      })
      .catch((e) => {
        console.error(JSON.stringify(e, null, 2));
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  const rowsInLine = headerTabData.noofcolumnsinrow;
  let colSpanValue = 8;
  let modalWidth = "280px";
  if (rowsInLine) {
    colSpanValue = 24 / parseInt(rowsInLine);
    if (colSpanValue === 12) {
      modalWidth = "520px";
    } else if (colSpanValue === 24) {
      modalWidth = "280px";
    } else if (colSpanValue === 8) {
      modalWidth = "800px";
    } else {
      modalWidth = "auto";
    }
  }

  return (
    <div>
      <Row>
        <Col {...responsiveDesignForColumn}>
          <p style={Themes.contentWindow.ListWindowHeader.listWindowTitle}>{windowDefinition.name} </p>
        </Col>
        <Col {...responsiveDesignForColumn}>
          {windowDefinition.enablenew === "Y" ? (
            <Button onClick={() => history.push(`/window/${windowDefinition.ad_window_id}/NEW_RECORD`)} style={Themes.contentWindow.ListWindowHeader.newButtonForlist}>
              New
            </Button>
          ) : null}
          {windowDefinition.enablequickadd === "Y" ? (
            <Button onClick={showQuickAdd} style={{ float: "right", marginRight: `${windowDefinition.enablequickadd === "Y" ? "8px" : "0px"}` }} className="quickAddButtons">
              <img style={Themes.contentWindow.ListWindowHeader.quickAddButtonImage} src={QuickAdd} alt="quickAdd" />
            </Button>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col {...responsiveSearch} style={{ paddingTop: "8px" }}>
          <AutoComplete style={{ width: "100%" }}>
            <Input
              placeholder="Search"
              value={searchInput}
              onChange={getSearchData}
              suffix={<i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.contentWindow.ListWindowHeader.listSearchIcon} />}
            />
          </AutoComplete>
        </Col>
        <Col {...responsiveSearch} style={{ paddingTop: "12px", paddingLeft: "15px" }}>
          <Dropdown disabled={true}>
            <span className="ant-dropdown-link" role="presentation" style={Themes.contentWindow.ListWindowHeader.viewTypeDropdown}>
              Recently viewed
              <span>
                <img src={DownArrow} alt="img" />
              </span>
            </span>
          </Dropdown>
        </Col>
        <Col {...responsiveButton} style={{ textAlign: "right", paddingTop: "8px" }}>
          <Dropdown trigger={["click"]} overlay={summaryMenu} onVisibleChange={handleSummaryVisibleChange} visible={summaryVisible}>
            <Tooltip title="Summary">
              <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
                <img style={{ paddingBottom: "6px", paddingRight: "1px", width: "11px" }} src={Summary} alt="invoice" />
              </Button>{" "}
            </Tooltip>
          </Dropdown>
          <Tooltip title="Selection">
            <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons} onClick={kanbanCards}>
              {/*  <img style={{ paddingBottom: "5px", paddingLeft: "1px" }} src={Reset} alt="invoice" />{" "} */}
              <img style={{ paddingBottom: "6px", width: "16px" }} src={Selection} alt="invoice" />
            </Button>{" "}
          </Tooltip>
          <Tooltip title="Reset">
            <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons} onClick={resetFilters}>
              <img style={{ paddingBottom: "5px", paddingLeft: "1px", width: "20px" }} src={Reset} alt="invoice" />{" "}
            </Button>{" "}
          </Tooltip>{" "}
          {/*
          &nbsp;
          <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
            <img style={{ paddingBottom: "6px", paddingLeft: "1px", width: "19px" }} src={Setting} alt="invoice" />{" "}
          </Button>
          &nbsp; */}
          <Dropdown trigger={["click"]} overlay={menu} onVisibleChange={handleVisibleChange} visible={visible}>
            <Tooltip title="ShowAndHide">
              <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
                <img style={{ paddingBottom: "5px", paddingLeft: "1px", width: "16px" }} src={ShowAndHide} alt="invoice" />{" "}
              </Button>{" "}
            </Tooltip>
          </Dropdown>
          <Tooltip title="Repeat">
            <Button onClick={refreshData} color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
              <img style={{ paddingBottom: "7px", paddingRight: "2px", width: "18px" }} src={Repeat} alt="invoice" />{" "}
            </Button>{" "}
          </Tooltip>{" "}
          {/*  
          &nbsp;
         {windowDefinition.enableattachment === "Y" ? (
            <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
              <img style={{ paddingBottom: "5px", paddingRight: "2px", width: "18px" }} src={Import} alt="invoice" />{" "}
            </Button>
          ) : null}
          &nbsp; */}
          {windowDefinition.enableprint === "Y" ? (
            <Tooltip title="Print">
              <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
                <img style={{ paddingBottom: "5px", paddingRight: "2px", width: "22px" }} src={Print} alt="invoice" />{" "}
              </Button>{" "}
            </Tooltip>
          ) : null}
          {windowDefinition.enablefilter === "Y" ? (
            <Tooltip title="Filter">
              <Button style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
                <img style={{ paddingBottom: "5px", paddingRight: "2px", width: "16px" }} src={Filter} alt="invoice" />{" "}
              </Button>{" "}
            </Tooltip>
          ) : null}
          <Tooltip title="Export">
            <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
              <img style={{ paddingBottom: "5px", paddingLeft: "1px", width: "16px" }} src={Export} alt="invoice" />
            </Button>{" "}
          </Tooltip>
          {headerTabData.enabletreeview === "Y" ? (
            <Tooltip title="TreeView">
              <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons} onClick={treeView}>
                <img style={{ paddingBottom: "6px", paddingRight: "1px" }} src={TreeView} alt="invoice" />
              </Button>{" "}
            </Tooltip>
          ) : (
            ""
          )}
          {windowDefinition.enableedit === "Y" && selectedRowKeys.length === 1 ? (
            <Tooltip title="Edit">
              <Button onClick={editRecord} color="primary" style={Themes.contentWindow.ListWindowHeader.listActionEditButtons}>
                <img style={{ paddingBottom: "6px", paddingRight: "1px", width: "15px" }} src={Edit} alt="invoice" />
              </Button>{" "}
            </Tooltip>
          ) : null}
          {windowDefinition.enabledelete === "Y" && selectedRowKeys.length >= 1 ? (
            <Tooltip title="Trash">
              <Button onClick={deleteRecords} style={Themes.contentWindow.ListWindowHeader.listActionEditButtons}>
                <img style={{ paddingBottom: "6px", width: "12px" }} src={Trash} alt="invoice" />
              </Button>{" "}
            </Tooltip>
          ) : null}
          {/*   <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
            <img style={{ paddingBottom: "6px", fontWeight: "600", width: "20px", paddingRight: "2px" }} src={Options} alt="invoice" />
          </Button> */}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginTop: "0px" }}>
          {isAlertActive ? (
            <Alert
              style={{
                width: "400px",
                position: "absolute",
                zIndex: 111,
                right: 0,
                top: "8px",
                borderLeft: "5px solid #c13832",
                borderRight: "0.5px solid #c13832",
                borderBottom: "0.5px solid #c13832",
                borderTop: "0.5px solid #c13832",
                backgroundColor: "white",
              }}
              message="Error"
              description="There is an error processing your request !"
              type="error"
              closable
              onClose={() => setIsAlertActive(false)}
              action={
                <Button onClick={displayErrorDetails} size="small" style={{ border: "0px solid #c13832", color: "#c13832", fontSize: "13px", marginTop: "6px" }} danger>
                  Detail
                </Button>
              }
            />
          ) : null}
        </Col>
      </Row>
      <Modal
        visible={visibleQuickAddModal}
        onCancel={() => setVisibleQuickAddModal(false)}
        getContainer={false}
        width={modalWidth}
        footer={[
          <Button key="save" style={{ border: "0px solid #000000" }} disabled={formLoading} onClick={() => setVisibleQuickAddModal(false)}>
            Cancel
          </Button>,
          <Button
            key="save-next"
            type="primary"
            disabled={formLoading}
            style={Themes.contentWindow.recordWindow.linesTab.popUpNewButton}
            loading={formLoading}
            onClick={handleSave}
          >
            Save
          </Button>,
        ]}
        title={"Quick Add"}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={formLoading}>
          <Scrollbars
            style={{
              height: "60vh",
            }}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            thumbSize={90}
            renderView={renderView}
            renderThumbHorizontal={renderThumb}
            renderThumbVertical={renderThumb}
          >
            <RecordForm
              form={form}
              idName="quickAdd"
              onFinish={onFinish}
              headerTab={headerTabData}
              headerRecord={headerRecordData}
              headerFieldGroups={headerFieldGroups}
              recordId={"NEW_RECORD"}
            />
          </Scrollbars>
        </Spin>
      </Modal>
    </div>
  );
};

export default ListWindowHeader;
