/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Spin, Select, DatePicker, Form, message,  Menu, Dropdown, Modal } from "antd";
import { ExportToCsv } from 'export-to-csv'
import TableForReport from './TableForReport'
// import NumberFormat from 'react-number-format'
// import { useGlobalContext } from "../../lib/storage";

import { LoadingOutlined } from "@ant-design/icons";
import { genericUrl } from "../../constants/serverConfig.js";
import { getComboFillForReport } from "../../services/generic";
import Axios from "axios";
import "antd/dist/antd.css";
import "../../styles/app.css";
// import "../../styles/antd.css"

const { RangePicker } = DatePicker;

const { Option } = Select;
const dateFormat = "YYYY/MM/DD";

const Report = () => {
  /* const { globalStore } = useGlobalContext();
  const Themes = JSON.parse(globalStore.userData.CW360_V2_UI); */
  const { reportId } = useParams();
  const [gridLength, setGridLength] = useState(0);
  const [reportName, setReportName] = useState("");
  const [parameters, setParameters] = useState("")
  const [reportFilters, setReportFilters] = useState([]);
  const [reportFields, setReportFields] = useState([]);
  const [dropdownDetails, setDropdownDetails] = useState([]);
  const [referenceListDetails, setListReferenceDetails] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [drillDownHeader,setHeaderForDrillDown ] = useState([]);
  const [drillDownData, setDataForDrillDown] = useState([]);
  const [drillDownPopup, setPopupForDrillDown] = useState(false);
  const [drillDownTitle, setDrillDownTitle] = useState("");
  // const [summaryFields, setSummaryFields] = useState([]);
  // const [summaryFieldsForDrillDown, setSummaryFieldsForDrillDown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingForModal, setLoadingForModal] = useState(false);
  const [popUpReportName, setPopUpReportName] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (reportId) {
      getReportData();
    }
    return () => {
      setHeaderData([]);
      setGridData([]);
      form.resetFields();
    };
  }, [reportId]);

  const getReportData = async () => {
    try {
      setLoading(true);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const getReportDetailsQuery = {
        query: `query {
        getReportJson(reportId:"${reportId}"){data, messageCode, title, message}
        }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const serverResponse = await Axios.post(genericUrl, getReportDetailsQuery, { headers: headers }, { async: true }, { crossDomain: true });
      const reportJsonResponse = serverResponse.data.data.getReportJson;
      
      if (reportJsonResponse.messageCode === "200") {
        const reportData = JSON.parse(reportJsonResponse.data);
        const reportName = reportData.name;
        const reportFilters = reportData.Filters;
        const reportFields = reportData.Fields;
        setLoading(false);
        setReportName(reportName);
        setReportFilters(reportFilters);
        setReportFields(reportFields);
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const onDropDownSelect = async (id) => {
    // console.log("======>Id<=====", id);
    const getReportData = await getComboFillForReport(id);
    // console.log("======>getReportData<=====", getReportData);
    setDropdownDetails([...getReportData]);
  };


  const onDropDownSelectForListReference = (values) => {
    setListReferenceDetails([...values]);
  };

  const responsiveDesignForColumn = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 12,
    sm: 12,
    md: 12,
  };

  const handleMenuClick = (obj) => {
    if (obj.key === "view") {
      form.submit();
    } else {
      downloadData();
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const valuesArray = Object.values(values);
      const arrayToSend = {};
      let valuesData = "";
      for (let index = 0; index < reportFilters.length; index += 1) {
        if (reportFilters[index].type === "Date") {
          valuesData = new Date(valuesArray[index]).toISOString();
        } /* else if (filtersData[index].type === 'Flag') {
        valuesData = flagValue
      } */ else {
          valuesData = valuesArray[index];
        }
        arrayToSend[reportFilters[index].columnName] = valuesData === undefined ? null : valuesData;
      }
      const stringifiedJSON = JSON.stringify(arrayToSend);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const onSubmitQuery = {
        query: `query {
        executeReport(reportId:"${reportId}", reportParam:"${jsonToSend}"){data, messageCode, title, message}
      }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };
      const serverResponse = await Axios.post(genericUrl, onSubmitQuery, { headers: headers }, { async: true }, { crossDomain: true });

      if (serverResponse.status === 200) {        
        const responseForGridData = serverResponse.data.data.executeReport.data;
        const gridData = JSON.parse(responseForGridData);
        const reportsGridArray = [];
        const headerArray = [];
        const finalArrayToPush = [];
        // const summaryFieldsArr = []
        let dataArray;

        if (gridData.length > 0) {
          for (let index = 0; index < gridData.length; index += 1) {
            // console.log("=======gridData=========",typeof(gridData[index]))
            if (typeof gridData[index] === "string" ) {
              dataArray = JSON.parse(gridData[index]);
              reportsGridArray.push(dataArray);
            } else {
              dataArray = gridData[index];
              reportsGridArray.push(dataArray);
            }
          }

          for (let index = 0; index < reportsGridArray.length; index++) {
            const gridData = reportsGridArray[index];
            for (let indexF = 0; indexF < reportFields.length; indexF++) {
              const fieldData = reportFields[indexF];
              if(fieldData.type==="List"){
                const fieldName = fieldData.fieldName
                const valuesArr = fieldData.Values
                for (let indexV = 0; indexV < valuesArr.length; indexV++) {
                  const valueElement = valuesArr[indexV];    
                  if(valueElement.key===gridData[fieldName]) {
                    gridData[fieldName]=valueElement.value
                  }       
                }
              }
              if(fieldData.type==="Yes/No"){
                const fieldName = fieldData.fieldName
                if(gridData[fieldName]==="N"){
                  gridData[fieldName]="No"
                }else{
                  gridData[fieldName]="Yes"
                }
              }
              if(fieldData.type==="WYSIWYG Editor"){
                const fieldName = fieldData.fieldName
                gridData[fieldName] = <div dangerouslySetInnerHTML={{ __html: gridData[fieldName] }}></div>
              }
            }
          }

          const keysArray = Object.keys(reportsGridArray[0]);         
          for (let i = 0; i < keysArray.length; i += 1) {
            for (let index = 0; index < reportFields.length; index += 1) {             
              if (keysArray[i] === reportFields[index].fieldName) {
                finalArrayToPush.push(reportFields[index]);                
                // summaryFieldsArr.push(reportFields[index].fieldName);
                break;
              }              
            }
          }

          
          for (let index = 0; index < finalArrayToPush.length; index += 1) {
            headerArray.push({
              // headerName: filtersData[index].displayName,
              title: <span className="dragHandler">{finalArrayToPush[index].displayName}</span>,
              dataIndex: finalArrayToPush[index].fieldName,
              type: finalArrayToPush[index].type,
              // key: index,
              width: 180,
              ellipsis: true,
              render: (text) => finalArrayToPush[index].drillDown==="Y"?<a>{text}</a>:text,
              onCell: (record) => ({
                onClick: () => {
                  drillDown(jsonToSend,finalArrayToPush[index].fieldName,record[finalArrayToPush[index].fieldName],finalArrayToPush[index].detailReportId)
                },
              })
              /* render: (text, record, index) => {
                console.log("text, record, index",text, record, index)
              } */
            });
            // pivotDisplayNameArray.push(finalArrayToPush[index].displayName)
          }
          setGridData(reportsGridArray);
          setHeaderData(headerArray);
          setLoading(false);
          setParameters(jsonToSend)
          // setSummaryFields(summaryFieldsArr)
        }else{
          message.error("No Data Available")
          setLoading(false);
        }
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const drillDown = async (parametersData,fieldName,record,drillDownId)=>{   
    try {    
      const parametersFromState = parametersData
      const replacedString = parametersFromState.replace(/\\/g, '')
      const parsedJson = JSON.parse(replacedString)
      parsedJson[fieldName]=record
      const stringifiedJSON = JSON.stringify(parsedJson);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      let dataArray;
      let reportsGridArray=[]
      const headerArrayForDrillDown = []
      // const summaryFieldsForDrillDown = []
      const finalArrayToPush = []
      // const summaryFieldsArr = []

      if(drillDownId!==null){
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
          Authorization: `bearer ${newToken.access_token}`,
        };
        setLoadingForModal(true)
        const getReportDetailsQuery = {
          query: `query {
          getReportJson(reportId:"${drillDownId}"){data, messageCode, title, message}
          }`,
        };
  
        const getReportJsonServerResponse = await Axios.post(genericUrl, getReportDetailsQuery, { headers: headers }, { async: true }, { crossDomain: true });
        const reportJsonResponse = getReportJsonServerResponse.data.data.getReportJson;

        if (reportJsonResponse.messageCode === "200") {
          const reportData = JSON.parse(reportJsonResponse.data);
          const reportName = reportData.name;
          setPopUpReportName(reportName)
          const drillDownFields = reportData.Fields
          const onSubmitQuery = {
            query: `query {
            executeReport(parentReportId:"${reportId}",reportId:"${drillDownId}", reportParam:"${jsonToSend}"){data, messageCode, title, message}
          }`,
          };
          
          const serverResponse = await Axios.post(genericUrl, onSubmitQuery, { headers: headers }, { async: true }, { crossDomain: true });
          const reportResponse = serverResponse.data.data.executeReport
          if(reportResponse.title==="Success"){
          setLoading(false);
          setLoadingForModal(false)
          const gridData = JSON.parse(reportResponse.data);
          // console.log("=====>gridData<======",gridData.length)
          setGridLength(gridData.length)
          if (gridData.length > 0) {
            for (let index = 0; index < gridData.length; index += 1) {
              // console.log("=======gridData=========",typeof(gridData[index]))
              if (typeof gridData[index] === "string") {
                dataArray = JSON.parse(gridData[index]);
                reportsGridArray.push(dataArray);
              } else {
                dataArray = gridData[index];
                reportsGridArray.push(dataArray);
              }
            }

            for (let index = 0; index < reportsGridArray.length; index++) {
              const gridData = reportsGridArray[index];
              for (let indexF = 0; indexF < drillDownFields.length; indexF++) {
                const fieldData = drillDownFields[indexF];
                if(fieldData.type==="List"){
                  const fieldName = fieldData.fieldName
                  const valuesArr = fieldData.Values
                  for (let indexV = 0; indexV < valuesArr.length; indexV++) {
                    const valueElement = valuesArr[indexV];    
                    if(valueElement.key===gridData[fieldName]) {
                      gridData[fieldName]=valueElement.value
                    }       
                  }
                }
                if(fieldData.type==="Yes/No"){
                  const fieldName = fieldData.fieldName
                  if(gridData[fieldName]==="N"){
                    gridData[fieldName]="No"
                  }else{
                    gridData[fieldName]="Yes"
                  }
                }
                if(fieldData.type==="WYSIWYG Editor"){
                  const fieldName = fieldData.fieldName
                  gridData[fieldName] = <div dangerouslySetInnerHTML={{ __html: gridData[fieldName] }}></div>
                }
              }
            }
  
  
            const keysArray = Object.keys(reportsGridArray[0]);         
            for (let i = 0; i < keysArray.length; i += 1) {
              for (let index = 0; index < drillDownFields.length; index += 1) {             
                if (keysArray[i] === drillDownFields[index].fieldName) {
                  finalArrayToPush.push(drillDownFields[index]);                  
                  // summaryFieldsArr.push(drillDownFields[index].fieldName);                  
                  break;
                }              
              }
            }
            // console.log("=========finalArrayToPush in Drilldown=========",finalArrayToPush)
            // const keysArray = Object.keys(reportsGridArray[0]);
            for (let index = 0; index < finalArrayToPush.length; index+=1) {
              const element = finalArrayToPush[index];  
              /* for (let index = 0; index < drillDownFields.length; index++) {
                const element = drillDownFields[index];
              } */
              // summaryFieldsForDrillDown.push(element.fieldName)
              headerArrayForDrillDown.push({
                title: <span className="dragHandler">{element.displayName}</span>,
                dataIndex: element.fieldName,
                type: element.type,
                // key: index,
                render: (text) => finalArrayToPush[index].drillDown==="Y"?<a>{text}</a>:text,
                onCell: (record) => ({
                  onClick: () => {
                    drillDown(jsonToSend,finalArrayToPush[index].fieldName,record[finalArrayToPush[index].fieldName],finalArrayToPush[index].detailReportId)
                  },
                }),
                width: 180,
                ellipsis: true,
              })          
            }
            // console.log("=====headerArrayForDrillDown======",headerArrayForDrillDown)
            setHeaderForDrillDown(headerArrayForDrillDown)
            setDataForDrillDown(reportsGridArray)
            setDrillDownTitle(record)
            setPopupForDrillDown(true)
            // setSummaryFieldsForDrillDown(summaryFieldsForDrillDown)

          }else{
            message.error("No data present")
            setLoading(false);
            setLoadingForModal(false)
          }
          }else if(reportResponse.title==="Error"){
            message.error(reportResponse.message)
            setLoadingForModal(false)
            setLoading(false)
          }
        }
      }else{
        console.log("=======>Drill Down Id<=====",drillDownId)
        /* setHeaderForDrillDown([])
        setDataForDrillDown([])
        setDrillDownTitle(record) */
      }
      
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  }

  /**
   * @description:-downloadData() downloads the data in CSV file.
   * @author:-Nikhil Gankar
   * @version 1.0
   * @since 31-08-2020
   */

  const downloadData = async () => {
    try {
      setLoading(true)
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const downloadMutation = {
        query: `query {
        downloadReport(reportId:"${reportId}", reportParam:"${parameters}"){data, messageCode, title, message}
      }`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const response = await Axios.post(genericUrl, downloadMutation, { headers: headers }, { async: true }, { crossDomain: true });
      const responseFromServer = response.data.data.downloadReport
      if(responseFromServer.title==="Success"){
        const dataForDownload = JSON.parse(responseFromServer.data)
        const parsedArray = []
        for (let index = 0; index < dataForDownload.length; index++) {
          const element = dataForDownload[index];
          parsedArray.push(JSON.parse(element))
        }
        // console.log("===parsed array====",parsedArray)
        const options = {
          fieldSeparator: ',',
          filename: reportName,
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(parsedArray)
        setLoading(false)
      }else{
        message.error(responseFromServer.message)
        setLoading(false)
      }
      
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="view">
        <span>View</span>
      </Menu.Item>
      <Menu.Item key="download">
        <span>Download</span>
      </Menu.Item>
    </Menu>
  );

  const handleOk=()=>{
    setPopupForDrillDown(false)
  }

  const handleCancel=()=>{
    setPopupForDrillDown(false)
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loading}>
      <Row>
        <Col {...responsiveDesignForColumn} style={{ marginBottom: "8px" }}>
          <h2>
            <b>{reportName}</b>
          </h2>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24} style={{ marginBottom: "8px" }}>
          <Card>
            <Row gutter={8}>
              <Col span={20}>
                <Row gutter={8}>
                  {reportFilters !== undefined
                    ? reportFilters.map((filtersData, index) => {
                        return (
                          <Col span={6} key={index} style={{ display: filtersData.isForPrompting === "Y" ? "block" : "none" }}>
                            <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                              <Form.Item
                                label={filtersData.displayName}
                                name={filtersData.columnName}
                                rules={[{ required: filtersData.mandatory === "Y" ? true : false, message: `Please Enter ${filtersData.displayName}` }]}
                              >
                                {filtersData.type === "MultiSelector" ? (
                                  <Select
                                    style={{ width: "100%" }}
                                    // onSearch={searchDropdownRecords}
                                    mode="multiple"
                                    maxTagCount={1}
                                    showSearch
                                    allowClear
                                    // notFoundContent={fetching ? <Spin size="small" /> : null}
                                    dropdownMatchSelectWidth={false}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onFocus={() => onDropDownSelect(filtersData.id)}
                                  >
                                    {/* {dropdownList} */}
                                    {dropdownDetails === null || dropdownDetails === undefined
                                      ? null
                                      : dropdownDetails.map((data) => {
                                          // console.log("===>data<====", data);
                                          return (
                                            <Option key={data.recordid} value={data.recordid}>
                                              {data.name}
                                            </Option>
                                          );
                                        })}
                                  </Select>
                                ) : filtersData.type === "DateRange" ? (
                                  <RangePicker format={dateFormat} />
                                ) : filtersData.type === "List" ? (
                                  <Select
                                    showSearch
                                    allowClear
                                    // notFoundContent={fetching ? <Spin size="small" /> : null}
                                    dropdownMatchSelectWidth={false}
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onFocus={() => onDropDownSelectForListReference(filtersData.Values)}
                                  >
                                    {referenceListDetails === null || referenceListDetails === undefined
                                      ? null
                                      : referenceListDetails.map((data) => {
                                          // console.log("===>data<====", data);
                                          return (
                                            <Option key={data.key} title={data.key} value={data.key}>
                                              {data.value}
                                            </Option>
                                          );
                                        })}
                                  </Select>
                                ) : filtersData.type === "Selector" ? (
                                  <Select
                                    showSearch
                                    allowClear
                                    // notFoundContent={fetching ? <Spin size="small" /> : null}
                                    dropdownMatchSelectWidth={false}
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onFocus={() => onDropDownSelect(filtersData.id)}
                                  >
                                    {dropdownDetails === null || dropdownDetails === undefined
                                      ? null
                                      : dropdownDetails.map((data) => {
                                          // console.log("===>data<====", data);
                                          return (
                                            <Option key={data.recordid} value={data.recordid}>
                                              {data.name}
                                            </Option>
                                          );
                                        })}
                                  </Select>
                                ) : filtersData.type === "Date" ? (
                                  <DatePicker style={{ width: "100%" }} format={dateFormat} />
                                ) : (
                                  ""
                                )}
                              </Form.Item>
                            </Form>
                          </Col>
                        );
                      })
                    : ""}
                </Row>
              </Col>
              <Col span={4}>
                <Dropdown.Button style={{ float: "right", marginTop: "14%" }} overlay={menu} type="primary">
                  Action
                </Dropdown.Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginBottom: "8px" }}>
          <Card>
            <TableForReport columnsData={headerData} gridData={gridData} />
          </Card>
        </Col>
      </Row>
      <div>
        <Modal title={<Spin indicator={<LoadingOutlined style={{ fontSize: "10px" }} spin />} spinning={loadingForModal}>{popUpReportName} - {drillDownTitle}</Spin>} visible={drillDownPopup} onOk={handleOk} onCancel={handleCancel} centered width="1000px">
          <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loadingForModal}>
           <TableForReport columnsData={drillDownHeader} gridData={drillDownData} />
          </Spin>
        </Modal>
      </div>
    </Spin>
  );
};

export default Report;
