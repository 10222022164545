import React, { useState } from "react";
import { Card } from "antd";
import { useGlobalContext } from "../../lib/storage";
import Theme from "../../constants/UIServer.json";
import RecordHeader from "./RecordHeader";
import RecordLines from "./RecordLines";
import RecordTitle from "./RecordTitle";

const RecordWindow = (props) => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI === undefined ? Theme : JSON.parse(globalStore.userData.CW360_V2_UI);
  const [lastRefreshed, setLastRefreshed] = useState(new Date());
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [headerRecordData, setHeaderRecordData] = useState({});

  return (
    <Card style={Themes.contentWindow.recordWindow.mainCard}>
      <RecordTitle lastRefreshed={lastRefreshed} setLastRefreshed={setLastRefreshed} headerRecordData={headerRecordData} {...props} />
      <RecordHeader setIsHeaderActive={setIsHeaderActive} lastRefreshed={lastRefreshed} setLastRefreshed={setLastRefreshed} setHeaderRecordData={setHeaderRecordData} {...props} />
      <RecordLines isHeaderActive={isHeaderActive} lastRefreshed={lastRefreshed} setLastRefreshed={setLastRefreshed} headerRecordData={headerRecordData} {...props} />
    </Card>
  );
};

export default RecordWindow;
