import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { Menu, Layout, Card, Select, message, Row, Col } from "antd";
import { useGlobalContext } from "../../lib/storage";
import Theme from "../../constants/UIServer.json";
import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Option, OptGroup } = Select;

const SideMenu = (props) => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI === undefined ? Theme : JSON.parse(globalStore.userData.CW360_V2_UI);
  const { sideMenuData: menuList, windowTabs, userPreferences } = globalStore;
  const { menuToggle } = props;
  const [menuData, setMenuData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }
  }, [menuList]);

  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const NavigateToMenu = (menuType, menuId, menuTitle) => {
    let navigationUrl;
    switch (menuType) {
      case "Report":
        navigationUrl = `/reports/report/${menuId}`;
        break;
      case "Dashboard":
        navigationUrl = `/analytics/dashboard/${menuId}`;
        break;
      case "Generic":
        navigationUrl = `/window/list/${menuId}`;
        break;
      case "Custom":
        navigationUrl = `/others/window/${menuId}`;
        break;
      default:
        message.warning("Not Available");
        break;
    }
    if (navigationUrl) {
      if (userPreferences.enableMultiTab === "Y") {
        const prevWindowTabs = [...windowTabs];
        if (prevWindowTabs.findIndex((tab) => tab.url === navigationUrl) < 0) {
          const newWindowTab = {
            url: navigationUrl,
            title: menuTitle,
            content: null,
          };
          setGlobalStore({ windowTabs: [...prevWindowTabs, newWindowTab] });
          history.push(navigationUrl);
        } else {
          message.warning("Tab Already Active");
        }
      } else {
        history.push(navigationUrl);
      }
    }
  };

  const selectMenu = (value, data) => {
    const valueData = value.split("@");
    if (valueData.length > 0) {
      NavigateToMenu(valueData[1], data.key, data.children);
    }
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  return (
    <Sider collapsed={menuToggle} onCollapse={null} style={Themes.sideMenu}>
      <Card style={Themes.sideMenu.sideMenuCard}>
        {menuToggle ? null : (
          <Select
            showSearch
            style={{ width: "100%", paddingRight: "8px" }}
            suffixIcon={<i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />}
            onSelect={selectMenu}
            value={null}
            placeholder={
              <Row>
                <Col span={4}>
                  <i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />
                </Col>
                <Col span={20}>Search...</Col>
              </Row>
            }
            showArrow={false}
            className="search-arrow placeHolder"
          >
            {menuData.map((menu, index) => (
              <OptGroup key={`${menu.title}-${index}`} label={menu.title}>
                {menu.children
                  ? menu.children.map((subMenuItem) => (
                      <Option key={subMenuItem.id} value={`${subMenuItem.title}@${subMenuItem.type}`}>
                        {subMenuItem.title}
                      </Option>
                    ))
                  : null}
              </OptGroup>
            ))}
          </Select>
        )}
        <Scrollbars
          style={{
            height: "85vh",
          }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          thumbSize={90}
          renderView={renderView}
          renderThumbHorizontal={renderThumb}
          renderThumbVertical={renderThumb}
        >
          <Menu theme="light" mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
            {menuData.map((menu) => (
              <SubMenu
                key={`${menu.key}`}
                icon={
                  <span className={menu.icon} style={{ color: "#666666" }}>
                    &ensp;
                  </span>
                }
                title={`${menu.title}`}
              >
                {menu.children
                  ? menu.children.map((subMenuItem, index) => (
                      <Menu.Item key={`${subMenuItem.key}-${index}`} onClick={() => NavigateToMenu(subMenuItem.type, subMenuItem.id, subMenuItem.title)} title={subMenuItem.title}>
                        {subMenuItem.title}
                      </Menu.Item>
                    ))
                  : null}
              </SubMenu>
            ))}
          </Menu>
        </Scrollbars>
      </Card>
    </Sider>
  );
};

export default SideMenu;
