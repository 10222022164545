import React, { useEffect, useRef, useState } from 'react';
import { select, scaleBand, axisBottom, scaleLinear, axisLeft, max, line } from "d3";

const useResizeObserver = ref => {
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach(entry => {
                setDimensions(entry.contentRect);
            })
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return dimensions;
};

const CombinationChartSingleYAxisComponent=(props)=> {
    // console.log("===>CombinationChartSingleYAxisComponent props<===",props)
    const data = props.combinationChartSingleYAxisdata;
    const properties = props.combinationChartSingleYAxisProperties;
    // console.log(data);
    // console.log(properties);
    let array = [];
    if (data !== undefined && data.length > 0) {
        for (let index = 1; index < data[0].length; index++) {
            let arr = [];
            for (let index1 = 0; index1 < data.length; index1++) {
              if (index1 === 0) {
                arr.push(data[index1][index]);
              } else {
                arr.push(Number(data[index1][index]));
              }
            }
            array.push(arr);
        }
    };
    let keys = [];
    if (data !== undefined && data.length > 0) {
        for (let index = 1; index < data.length; index++) {
            keys.push(data[index][0]);
        } 
    };
    // console.log(array.slice(0, 11));
    const slicedArr = array.slice(0, 11)
    // console.log(keys);

    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    useEffect(() => {
        const svg = select(svgRef.current);

        if (!dimensions) return;

        const xScale = scaleBand()
            .domain(props.fullScreenValue===false?slicedArr.map(d => d[0]):array.map(d => d[0]))
            .range([50, dimensions.width/1.1])
            .padding(0.5);
            
        const yScale = scaleLinear()
            .domain([0, max(array, d => d[1])])
            .range([dimensions.height/1.4, 0]);

        const xAxis = axisBottom(xScale);

        const yAxis = axisLeft(yScale).tickFormat((d) => { if (d / 1000000000 >= 1) {
            return +(d / 1000000000).toFixed(1) + ' B'
          } else if (d / 1000000 >= 1) {
            return +(d / 1000000).toFixed(1) + ' M'
          } else if (d / 1000 >= 1) {
            return +(d / 1000).toFixed(1) + ' K'
          } else {
            return d
        }});

        svg
            .select(".x-axis")
            .style("transform", `translateY(${dimensions.height/1.4}px)`)
            .call(xAxis)
            .selectAll("text")
            .attr("transform", "translate(10)rotate(-20)")
            .style("text-anchor", "end")
            .style("font-size", 10)
            .style("fill", "black");

        svg
            .select(".y-axis")
            .style("transform", `translateX(${50}px)`)
            .call(yAxis);

        const myLine = line()
            .x((d, i) => xScale(d[0]) + xScale.bandwidth() / 2)
            .y(d => yScale(d[2]));

        svg
            .selectAll(".bar")
            .data(props.fullScreenValue===false?slicedArr:array)
            .join("rect")
            .attr("class", "bar")
            .style("transform", "scale(1, -1)")
            .attr("x", d => xScale(d[0]))
            .attr("y", -dimensions.height/1.4)
            .attr("width", xScale.bandwidth())
            .attr("fill", `${properties.prevYearColor}`)
            .attr("height", d => dimensions.height/1.4 - yScale(d[1]));

        svg 
            .selectAll(".line")
            .data(props.fullScreenValue===false?slicedArr:array)
            .join("path")
            .attr("class", "line")
            .attr("d", myLine(props.fullScreenValue===false?slicedArr:array))
            .attr("fill", "none")
            .attr("stroke", `${properties.lineColor}`);
        
    }, [dimensions, keys, array]);

    return (
        // <div>
        //     <b>
        //         CombinationChartSingleYAxis
        //     </b>
        // </div>
        <div ref={wrapperRef} style={{
            height: props.fullScreenValue === true ? "75vh" : props.height === undefined || props.height === null ? "35vh" : `${props.height-5}vh`
        }}>
            <svg ref={svgRef} style={{
                background: `${properties.bgcolor}`,
                overflow: 'visible',
                display: 'block',
                width: '100%',
                height: props.fullScreenValue === true ? "75vh" : props.height === undefined || props.height === null ? "35vh" : `${props.height-5}vh`
            }}>
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
        </div>
    )
}

export default CombinationChartSingleYAxisComponent
