import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useWindowContext, useGlobalContext } from "../../../lib/storage";
import { getCustomRoleData } from "../../../services/generic";

import "antd/dist/antd.css";

const UserWindowLines = (props) => {
  const { globalStore } = useGlobalContext();
 // const Themes = JSON.parse(globalStore.userData.CW360_V2_UI);
  const [loading, setLoading] = useState(false);
  const [dataSourceRecords, setDataSourceRecords] = useState([]);
  // const { windowStore } = useWindowContext();
  // const { windowStore, setWindowStore } = useWindowContext();

  const history = useHistory();

  const columnsData = [
    {
      width: 50,
      checkboxSelection(params) {
        return params.columnApi.getRowGroupColumns().length === 0
      },
      headerCheckboxSelection(params) {
        return params.columnApi.getRowGroupColumns().length === 0
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Active',
      dataIndex: 'isactive',
      render: text => <span>{text === "true" || text === "Y" ? <i class="fa fa-check" aria-hidden="true" /> : <i class="fa fa-times" aria-hidden="true" />}</span>,
      
    },
    {
      title: 'Admin',
      dataIndex: 'isadmin',
      render: text => <span>{text === "true" || text === "Y" ? <i class="fa fa-check" aria-hidden="true" /> : <i class="fa fa-times" aria-hidden="true" />}</span>,

    },
  ]

  useEffect(async () => {
    setLoading(true);
    const getData = await getCustomRoleData();
    setDataSourceRecords([...getData]);
    setLoading(false);
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (keys) => {

    setSelectedRowKeys([keys[keys.length - 1]]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
    fixed: true,
  };


  return (
    <Table
     // style={Themes.contentWindow.ListWindowLines.listWindowTable}
      size="small"
      scroll={{ y: "72vh", x: "100%" }}
      sticky={true}
      pagination={false}
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />,
      }}
      dataSource={dataSourceRecords}
      columns={columnsData}
      rowSelection={rowSelection}
      onRow={(row) => ({
        
        onClick: () => {
          localStorage.setItem("csRoleId", row['csRoleId']);
          history.push(`/others/window/RoleDetails`);
        },
      })}
    />

  );
};

export default UserWindowLines;
