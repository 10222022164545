import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu, notification, Spin, Modal, Table, Row, Col, Button, AutoComplete, Input, Card, Form, Select, Checkbox, Tabs } from "antd";
import { useGlobalContext } from "../../../lib/storage";
import {
  activeAdminRole,
  enableRoleBaseOnActiveMode,
  getUsersFromRole,
  enableAdminRole,
  enableActiveRole,
  getCopyRoleGrid,
  getUserRoleAccessTab,
  getModuleAccessData,
  getModulesDropdownData,
  getRolesFieldsAccess,
  updateRoleAccess,
  getRoleAccess,
} from "../../../services/generic";
import { LoadingOutlined } from "@ant-design/icons";
import InvoiceLogo from "../../../assets/images/invoice.svg";
import "antd/dist/antd.css";

const UserWindowHeader = (props) => {
  const history = useHistory();
  const { globalStore } = useGlobalContext();
  let usersData = JSON.parse(localStorage.getItem("userData"));
 // const Themes = JSON.parse(globalStore.userData.CW360_V2_UI);

  const [searchInput, setSearchInput] = useState("");

  const [usersBusinessUnitDropdown, setUsersBusinessUnitDropdown] = useState([]);
  const [loading, setLoading] = useState(false);

  const [lineRoleDetailsData, setLineRoleDetailsData] = useState([]);

  const [buModalVisible, setBuModalVisible] = useState(false);
  const [moduleAccessModal, setModuleAccessModal] = useState(false);
  const [usersRoleAccessDropdown, setUsersRoleAccessDropdown] = useState([]);

  const [autoGeneratePassFlag, setAutoGeneratePassFlag] = useState(true);
  const [roleName, setRoleName] = useState("");
  const [isCreatorCheck, setIsCreatorCheck] = useState(false);
  const [updateButtonCondition, setUpdateButtonCondition] = useState(false);
  const [processModalForModuleAccess, setProcessModalForModuleAccess] = useState(false);
  const [processDataForModuleAccess, setProcessDataForModuleAccess] = useState([]);
  const [moduleAccessdataSource, setModuleAccessdataSource] = useState([]);

  const [multipleModuleData, setMultipleModuleData] = useState([]);

  const [copyRolesProcessModal, setCopyRolesProcessModal] = useState(false);
  const [copyRolesProcessData, setCopyRolesProcessData] = useState([]);
  const [getUsersFromRoleModal, setGetUsersFromRoleModal] = useState(false);

  const [multipleModuleKeys, setMultipleModuleKeys] = useState([]);
  const [copyRoleModal, setCopyRoleModal] = useState(false);
  const [copyRolesData, setCopyRolesData] = useState([]);
  const [copyDropDownRolesData, setCopyDropDownRolesData] = useState([]);

  const [processData, setProcessData] = useState([]);
  const [processModal, setProcessModal] = useState(false);
  const [makeAdminModal, setMakeAdminModal] = useState(false);
  const [removeAdminModal, setRemoveAdminModal] = useState(false);
  const [usersBaseOnRolesData, setUsersBaseOnRolesData] = useState([]);

  const [isAdmin, setIsAdmin] = useState("");
  const [isActive, setIsActive] = useState("");
  const [form] = Form.useForm();
  const { TextArea, Password } = Input;
  const { Option } = Select;
  const [options, setOptions] = useState([]);
  const { TabPane } = Tabs;
  const moment = require("moment");
  const EditableContext = React.createContext(null);
  let roleId = localStorage.getItem("csRoleId");

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 12,
    sm: 12,
    md: 12,
  };
  const responsiveButton = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 24,
    sm: 24,
    md: 12,
  };

  const responsiveSearch = {
    xxl: 6,
    xl: 6,
    lg: 6,
    xs: 24,
    sm: 12,
    md: 6,
  };

  const accessToArray = [
    {
      name: "All",
      recordid: "All",
    },
    {
      name: "Window",
      recordid: "Window",
    },
    {
      name: "Report",
      recordid: "Report",
    },
    {
      name: "Dashboard",
      recordid: "Dashboard",
    },
  ];

  useEffect(async () => {
    setLoading(true);

    const getRoleAccessData = await getRoleAccess(roleId, isAdmin);

    form.setFieldsValue({
      roleName: getRoleAccessData["roleName"],
      description: getRoleAccessData["description"],
      menuId: getRoleAccessData["menu_id"],
    });
    setRoleName(getRoleAccessData["roleName"]);
    setLineRoleDetailsData(getRoleAccessData.window);
    setIsActive(getRoleAccessData["isActive"]);
    setIsAdmin(getModuleAccessData["isAdmin"]);

    setLoading(false);
  }, []);

  const onFinish = async (values) => {
    let roleName = values.roleName;
    let description = values.description;

    const newArr3 = lineRoleDetailsData;
    const newArray = [];
    for (let k = 0; k < newArr3.length; k += 1) {
      let newObj;
      if (newArr3[k].type === "Window") {
        newObj = {
          csWindowAccessId: newArr3[k].csWindowAccessId,
          delete: newArr3[k].delete === true ? "Y" : "N",
          download: newArr3[k].download === true ? "Y" : "N",
          id: newArr3[k].id,
          moduleId: newArr3[k].moduleId,
          moduleName: newArr3[k].moduleName,
          name: newArr3[k].name,
          process: newArr3[k].process,
          type: newArr3[k].type,
          view: newArr3[k].view === true ? "Y" : "N",
          write: newArr3[k].write === true ? "Y" : "N",
        };
      } else if (newArr3[k].type === "Report" || newArr3[k].type === "Dashboard") {
        newObj = {
          csReportAccessId: newArr3[k].csReportAccessId,
          delete: newArr3[k].delete === true ? "Y" : "N",
          download: newArr3[k].download === true ? "Y" : "N",
          id: newArr3[k].id,
          moduleId: newArr3[k].moduleId,
          moduleName: newArr3[k].moduleName,
          name: newArr3[k].name,
          process: newArr3[k].process,
          type: newArr3[k].type,
          view: newArr3[k].view === true ? "Y" : "N",
          write: newArr3[k].write === true ? "Y" : "N",
        };
      }
      newArray.push(newObj);
    }

    for (let m = 0; m < newArray.length; m += 1) {
      const element4 = newArray[m].process === null ? [] : newArray[m].process === undefined ? [] : newArray[m].process;
      for (let l = 0; l < element4.length; l += 1) {
        const element = element4[l];
        if (element.access === true) {
          element.access = "Y";
        } else if (element.access === false) {
          element.access = "N";
        }
      }
    }

    const roleAccess = {
      roleName: roleName,
      description: description,
      isAdmin: isAdmin,
      isActive: isActive,
      csRoleId: roleId,
      window: newArray,
    };

    const finalRoleAccess = JSON.stringify(JSON.stringify(roleAccess));
    const updateMainRoleDetails = await updateRoleAccess(finalRoleAccess);

    if (updateMainRoleDetails.messageCode == 200) {
      notification.success({
        message: updateMainRoleDetails.message,
      });
    } else {
      notification.info({
        message: updateMainRoleDetails.message,
      });
    }
  };

  const columnsForRoleDetails = [
    {
      title: "Module",
      dataIndex: "moduleName",
      key: "module",
      //  editable: true,
      /* // width: 180,
                  ...this.getColumnSearchPropsInMainGrid('moduleName'), */
    },
    {
      title: "Type",
      dataIndex: "type",
      // editable: true,
      /*  key: 'type',
                   ...this.getColumnSearchPropsInMainGrid('type'), */
    },
    {
      title: "Name",
      dataIndex: "name",
      //  editable: true,
      /*  key: 'name',
                   // width: 210,
                   ...this.getColumnSearchPropsInMainGrid('name'), */
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      editable: true,
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxChangeRoleFormainGrid(rowIndex, "view", record)} />,

      // render: text => <span>{text == true ||  text === "true" || text === "Y" ? <i class="fa fa-check" aria-hidden="true" /> : <i class="fa fa-times" aria-hidden="true" />}</span>,
    },
    {
      title: "Write",
      dataIndex: "write",
      editable: true,
      key: "write",
      render: (value, record, rowIndex) => (
        <span>
          {record.type === "Report" || record.type === "Dashboard" ? (
            <Checkbox disabled />
          ) : (
            <Checkbox checked={value} onChange={handleCheckboxChangeRoleFormainGrid(rowIndex, "write", record)} />
          )}
        </span>
      ),
      // render: text => <span>{text == true ||  text === "true" || text === "Y" ? <i class="fa fa-check" aria-hidden="true" /> : <i class="fa fa-times" aria-hidden="true" />}</span>,
    },
    {
      title: "Delete",
      dataIndex: "delete",
      editable: true,

      key: "delete",
      render: (value, record, rowIndex) => (
        <span>
          {record.type === "Report" || record.type === "Dashboard" ? (
            <Checkbox disabled />
          ) : (
            <Checkbox checked={value} onChange={handleCheckboxChangeRoleFormainGrid(rowIndex, "delete", record)} />
          )}
        </span>
      ),
      // render: text => <span>{text == true ||  text === "true" || text === "Y" ? <i class="fa fa-check" aria-hidden="true" /> : <i class="fa fa-times" aria-hidden="true" />}</span>,
    },
    {
      title: "Download",
      dataIndex: "download",
      editable: true,

      key: "download",
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxChangeRoleFormainGrid(rowIndex, "download", record)} />,
      // render: text => <span>{text == true ||  text === "true" || text === "Y" ? <i class="fa fa-check" aria-hidden="true" /> : <i class="fa fa-times" aria-hidden="true" />}</span>,
    },
    {
      title: "Process",
      key: "process",
      editable: true,

      render: (text) => (
        <span>
          {text.process === null || text.process === undefined ? (
            ""
          ) : (
            <div>
              <i
                className="fa fa-pencil"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  getProcessModal(text);
                }}
              />
              &nbsp;&nbsp;&nbsp;
              <span>
                {text.process.map((data) => (
                  <span>
                    {data.processName}:{data.access === true ? "YES |" : "NO |"}
                  </span>
                ))}
              </span>
            </div>
          )}
        </span>
      ),
      // render: text => <span>{text == true ||  text === "true" || text === "Y" ? <i class="fa fa-check" aria-hidden="true" /> : <i class="fa fa-times" aria-hidden="true" />}</span>,
    },
    {
      dataIndex: "process1",
      key: "process1",
    },
  ];

  const handleCheckboxChangeRoleFormainGrid = (rowIndex, columnKey, record) => (event) => {
    delete record[columnKey];
    record[columnKey] = event.target.checked;
    delete lineRoleDetailsData[rowIndex];
    lineRoleDetailsData[rowIndex] = record;
    var finalLineRoleDetails = [...lineRoleDetailsData];

    setLineRoleDetailsData(finalLineRoleDetails);
    setUpdateButtonCondition(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinishForLineRole = async (fieldsValue) => {
    const roleForLine = fieldsValue["lineRole"];
    let selectedBU = usersRoleAccessDropdown.find((o) => o.recordid === roleForLine);

    let values = [
      {
        roleId: roleForLine,
        csUserAccessId: null,
        roleName: selectedBU["name"],
        isCreator: isCreatorCheck,
      },
    ];

    var finalLineBusinessUnit = [...lineRoleDetailsData, ...values];
    setLineRoleDetailsData(finalLineBusinessUnit);
  };

  const handleCancel = () => {
    setBuModalVisible(false);
    setProcessModalForModuleAccess(false);
    setCopyRolesProcessModal(false);
    setCopyRoleModal(false);
    setRemoveAdminModal(false);
    setGetUsersFromRoleModal(false);
    setMakeAdminModal(false);
  };

  const displayModuleAccess = async () => {
    const getRolesFieldsValue = await getRolesFieldsAccess();
    const getModulesDropdownDatas = await getModulesDropdownData();
    setMultipleModuleData(getModulesDropdownDatas);
    setModuleAccessdataSource(getRolesFieldsValue["window"]);

    setModuleAccessModal(true);
  };

  const displayCopyRole = async () => {
    setCopyRoleModal(true);
    const getUserRoles = await getUserRoleAccessTab();

    setCopyDropDownRolesData(getUserRoles);
  };

  const handleCheckboxforAccess = (rowIndex, columnKey, record) => (event) => {
    const newCheckboxState = [...processData];
    newCheckboxState[rowIndex][columnKey] = event.target.checked;

    var finalProcessData = [...newCheckboxState];
    setProcessData(finalProcessData);
  };

  const getProcessModal = async (data) => {
    const proData = data.process;
    setProcessData(proData);
    setProcessModal(true);
    setUpdateButtonCondition(true);
  };

  const processModalClose = () => {
    setProcessModal(false);
    setModuleAccessModal(false);
  };

  const handleCheckboxChangeFactory = (rowIndex, columnKey, record) => (event) => {
    const newCheckboxState = [...moduleAccessdataSource];
    for (let index = 0; index < newCheckboxState.length; index++) {
      const element = newCheckboxState[index].id;
      if (element === record.id) {
        newCheckboxState[index][columnKey] = event.target.checked;
      }
    }
    setModuleAccessdataSource(newCheckboxState);
  };

  const getProcessModalForModuleAccess = (data) => {
    setProcessModalForModuleAccess(true);
    setProcessDataForModuleAccess(data.process);
  };

  const processColumns = [
    {
      title: "Process",
      dataIndex: "processName",
      key: "processn",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxforAccess(rowIndex, "access", record)} />,
    },
  ];

  const usersBaseOnRolesColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
  ];

  const ModuleAccessColumns = [
    {
      title: "Module",
      dataIndex: "moduleName",
      key: "module",
      //  ...this.getColumnSearchPropsInMainGrid('moduleName'),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      //  ...this.getColumnSearchPropsInMainGrid('type'),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //...this.getColumnSearchPropsInMainGrid('name'),
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxChangeFactory(rowIndex, "view", record)} />,
    },
    {
      title: "Write",
      dataIndex: "write",
      key: "write",
      render: (value, record, rowIndex) => (
        <span>
          {record.type === "Report" || record.type === "Dashboard" ? (
            <Checkbox disabled />
          ) : (
            <Checkbox checked={value} onChange={handleCheckboxChangeFactory(rowIndex, "write", record)} />
          )}
        </span>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (value, record, rowIndex) => (
        <span>
          {record.type === "Report" || record.type === "Dashboard" ? (
            <Checkbox disabled />
          ) : (
            <Checkbox checked={value} onChange={handleCheckboxChangeFactory(rowIndex, "delete", record)} />
          )}
        </span>
      ),
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxChangeFactory(rowIndex, "download", record)} />,
    },
    {
      title: "Process",
      key: "process",
      render: (text) => (
        <span>
          {text.process === null || text.process === undefined ? (
            ""
          ) : (
            <div>
              <i
                className="fa fa-pencil"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  getProcessModalForModuleAccess(text);
                }}
              />
            </div>
          )}
        </span>
      ),
    },
  ];

  const moduleMenudata = multipleModuleData.map((data) => {
    return (
      <Option key={data.recordid} value={data.recordid}>
        {data.name}
      </Option>
    );
  });

  const processColumnsForModuleAccess = [
    {
      title: "Process",
      dataIndex: "processName",
      key: "processn",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxforModuleProcess(rowIndex, "access", record)} />,
    },
  ];

  const handleCheckboxforModuleProcess = (rowIndex, columnKey, record) => (event) => {
    const newCheckboxState = [...processDataForModuleAccess];
    newCheckboxState[rowIndex][columnKey] = event.target.checked;

    var finalProcessData = [...newCheckboxState];
    setProcessDataForModuleAccess(finalProcessData);
  };

  const submitModuleAccess = () => {
    form.submit("moduleAccess");
  };

  const submitCopyRoles = () => {
    form.submit("copyRolesForm");
  };

  const onFinishModuleAccess = async (values) => {
    let roleName = values["roleName"];
    let accessTo = values["moduleAccessTo"];
    let moduleAccess = values["moduleAccess"];

    const newArr = moduleAccessdataSource;
    const newArr3 = newArr;
    const newArray = [];
    for (let k = 0; k < newArr3.length; k += 1) {
      let newObj;
      if (newArr3[k].type === "Window") {
        newObj = {
          csWindowAccessId: newArr3[k].csWindowAccessId,
          delete: newArr3[k].delete === true ? "Y" : "N",
          download: newArr3[k].download === true ? "Y" : "N",
          id: newArr3[k].id,
          moduleId: newArr3[k].moduleId,
          moduleName: newArr3[k].moduleName,
          name: newArr3[k].name,
          process: newArr3[k].process,
          type: newArr3[k].type,
          view: newArr3[k].view === true ? "Y" : "N",
          write: newArr3[k].write === true ? "Y" : "N",
        };
      } else if (newArr3[k].type === "Report" || newArr3[k].type === "Dashboard") {
        newObj = {
          csReportAccessId: newArr3[k].csReportAccessId,
          delete: newArr3[k].delete === true ? "Y" : "N",
          download: newArr3[k].download === true ? "Y" : "N",
          id: newArr3[k].id,
          moduleId: newArr3[k].moduleId,
          moduleName: newArr3[k].moduleName,
          name: newArr3[k].name,
          process: newArr3[k].process,
          type: newArr3[k].type,
          view: newArr3[k].view === true ? "Y" : "N",
          write: newArr3[k].write === true ? "Y" : "N",
        };
      }
      newArray.push(newObj);
    }
    for (let m = 0; m < newArray.length; m += 1) {
      const element4 = newArray[m].process === null ? [] : newArray[m].process === undefined ? [] : newArray[m].process;
      for (let l = 0; l < element4.length; l += 1) {
        const element = element4[l];
        if (element.access === true) {
          element.access = "Y";
        } else if (element.access === false) {
          element.access = "N";
        }
      }
    }

    const roleAccess = {
      roleName: roleName,
      // "description":description,
      isAdmin: isAdmin,
      isActive: isActive,
      csRoleId: roleId,
      window: newArray,
    };

    const finalRoleAccess = JSON.stringify(JSON.stringify(roleAccess));

    const updateMainRoleDetails = await updateRoleAccess(finalRoleAccess);

    if (updateMainRoleDetails.messageCode == 200) {
      notification.success({
        message: updateMainRoleDetails.message,
      });
    } else {
      notification.info({
        message: updateMainRoleDetails.message,
      });
    }
  };

  const selectAccessto = async (key) => {
    const moduleIds = JSON.stringify(JSON.stringify(multipleModuleKeys));

    const updateModualAccessGridBaseOnCondition = await getModuleAccessData(moduleIds, key);

    setModuleAccessdataSource(updateModualAccessGridBaseOnCondition["window"]);
  };

  const onModuleChange = (key) => {
    setMultipleModuleKeys(key);
  };

  const copyRoleColumns = [
    {
      title: "Module",
      dataIndex: "moduleName",
      key: "module",
      // ...this.getColumnSearchPropsInMainGrid('moduleName'),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      // ...this.getColumnSearchPropsInMainGrid('type'),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // ...this.getColumnSearchPropsInMainGrid('name'),
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxForCopyRole(rowIndex, "view", record)} />,
    },
    {
      title: "Write",
      dataIndex: "write",
      key: "write",
      render: (value, record, rowIndex) => (
        <span>
          {record.type === "Report" || record.type === "Dashboard" ? (
            <Checkbox disabled />
          ) : (
            <Checkbox checked={value} onChange={handleCheckboxForCopyRole(rowIndex, "write", record)} />
          )}
        </span>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (value, record, rowIndex) => (
        <span>
          {record.type === "Report" || record.type === "Dashboard" ? (
            <Checkbox disabled />
          ) : (
            <Checkbox checked={value} onChange={handleCheckboxForCopyRole(rowIndex, "delete", record)} />
          )}
        </span>
      ),
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxForCopyRole(rowIndex, "download", record)} />,
    },
    {
      title: "Process",
      key: "process",
      render: (text) => (
        <span>
          {text.process === null || text.process === undefined ? (
            ""
          ) : (
            <div>
              <i
                className="fa fa-pencil"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  getProcessModalforCopyrole(text);
                }}
              />
            </div>
          )}
        </span>
      ),
    },
  ];

  const onRolesChange = async (id) => {
    const getCopyRoleOptionsGridData = await getCopyRoleGrid(id);
    setCopyRolesData(getCopyRoleOptionsGridData.window);
  };

  const handleCheckboxForCopyRole = (rowIndex, columnKey, record) => (event) => {
    const newCheckboxState = [...copyRolesData];
    for (let index = 0; index < newCheckboxState.length; index++) {
      const element = newCheckboxState[index].id;
      if (element === record.id) {
        newCheckboxState[index][columnKey] = event.target.checked;
      }
    }
    setCopyRolesData(newCheckboxState);
  };

  const getProcessModalforCopyrole = (data) => {
    setCopyRolesProcessModal(true);
    setCopyRolesProcessData(data.process);
  };

  const handleCheckboxforCopyRoleProcess = (rowIndex, columnKey, record) => (event) => {
    const newCheckboxState = [...copyRolesProcessData];
    newCheckboxState[rowIndex][columnKey] = event.target.checked;

    var finalProcessData = [...newCheckboxState];
    setCopyRolesProcessData(finalProcessData);
  };

  const copyRoleprocessColumns = [
    {
      title: "Process",
      dataIndex: "processName",
      key: "processn",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      render: (value, record, rowIndex) => <Checkbox checked={value} onChange={handleCheckboxforCopyRoleProcess(rowIndex, "access", record)} />,
    },
  ];

  const onFinishCopyRoles = async (values) => {
    const newArr = copyRolesData;
    const newArr3 = newArr;
    const newArray = [];
    for (let k = 0; k < newArr3.length; k += 1) {
      let newObj;
      if (newArr3[k].type === "Window") {
        newObj = {
          csWindowAccessId: newArr3[k].csWindowAccessId,
          delete: newArr3[k].delete === true ? "Y" : "N",
          download: newArr3[k].download === true ? "Y" : "N",
          id: newArr3[k].id,
          moduleId: newArr3[k].moduleId,
          moduleName: newArr3[k].moduleName,
          name: newArr3[k].name,
          process: newArr3[k].process,
          type: newArr3[k].type,
          view: newArr3[k].view === true ? "Y" : "N",
          write: newArr3[k].write === true ? "Y" : "N",
        };
      } else if (newArr3[k].type === "Report" || newArr3[k].type === "Dashboard") {
        newObj = {
          csReportAccessId: newArr3[k].csReportAccessId,
          delete: newArr3[k].delete === true ? "Y" : "N",
          download: newArr3[k].download === true ? "Y" : "N",
          id: newArr3[k].id,
          moduleId: newArr3[k].moduleId,
          moduleName: newArr3[k].moduleName,
          name: newArr3[k].name,
          process: newArr3[k].process,
          type: newArr3[k].type,
          view: newArr3[k].view === true ? "Y" : "N",
          write: newArr3[k].write === true ? "Y" : "N",
        };
      }
      newArray.push(newObj);
    }
    for (let m = 0; m < newArray.length; m += 1) {
      const element4 = newArray[m].process === null ? [] : newArray[m].process === undefined ? [] : newArray[m].process;
      for (let l = 0; l < element4.length; l += 1) {
        const element = element4[l];
        if (element.access === true) {
          element.access = "Y";
        } else if (element.access === false) {
          element.access = "N";
        }
      }
    }

    const roleAccess = {
      roleName: roleName,
      // "description":description,
      isAdmin: isAdmin,
      isActive: isActive,
      csRoleId: roleId,
      window: newArray,
    };
    const finalRoleAccess = JSON.stringify(JSON.stringify(roleAccess));

    const updateMainRoleDetails = await updateRoleAccess(finalRoleAccess);

    if (updateMainRoleDetails.messageCode == 200) {
      notification.success({
        message: updateMainRoleDetails.message,
      });
    } else {
      notification.info({
        message: updateMainRoleDetails.message,
      });
    }
  };

  const content = (
    <Menu>
      {isActive === "N" ? (
        <Menu.Item key="1" style={{ color: "#314659" }} onClick={() => makeActive()}>
          Make Active
        </Menu.Item>
      ) : (
        <Menu.Item key="2" style={{ color: "#314659" }} onClick={() => makeInActive()}>
          Make In-Active
        </Menu.Item>
      )}
      {isAdmin === "N" ? (
        <Menu.Item key="3" style={{ color: "#314659" }} onClick={() => makeAdmin()}>
          Make Admin
        </Menu.Item>
      ) : (
        <Menu.Item key="4" style={{ color: "#314659" }} onClick={() => removeAdmin()}>
          Remove Admin
        </Menu.Item>
      )}
      <Menu.Item key="5" style={{ color: "#314659" }} onClick={() => getUsersModal()}>
        View Users
      </Menu.Item>
    </Menu>
  );

  const makeActive = async (roleId) => {
    const enableActiveRoleData = await enableRoleBaseOnActiveMode(roleId);

    if (enableActiveRoleData.messageCode == 200) {
      notification.success({
        message: enableActiveRoleData.message,
      });
      setIsActive("Y");
    } else {
      notification.info({
        message: enableActiveRoleData.message,
      });
    }
    /*  const newToken = localStorage.getItem('Token')
         const roleId = window.localStorage.getItem('roleIdcs')
         const enabledAdmin = {
             query: `mutation{enableActiveRole(roleId:"${roleId}",isActive:"Y")
               {messageCode   title    message}
             }`,
           }
           axios({
             url: GENERICURL,
             method: 'POST',
             async: true,
             crossDomain: true,
             data: enabledAdmin,
             headers: {
               'Content-Type': 'application/json',
               Authorization: `bearer ${newToken}`,
             },
           }).then(response => {
             if(response.data.data.enableActiveRole.messageCode === '200'){
                 message.success(response.data.data.enableActiveRole.message)
                 this.setState({isActive:'Y'})
                 this.roleDetails();
             }else{
                 message.success(response.data.data.enableActiveRole.message) 
             }
           }) */
  };

  const makeInActive = async () => {
    const enabledAdminView = await enableActiveRole(roleId);

    if (enabledAdminView.messageCode == 200) {
      notification.success({
        message: enabledAdminView.message,
      });
      setIsActive("N");
    } else {
      notification.info({
        message: enabledAdminView.message,
      });
    }
  };

  const makeAdmin = () => {
    setMakeAdminModal(true);
  };

  const removeAdmin = () => {
    setRemoveAdminModal(true);
  };

  const removeAdminAccess = async () => {
    const enableAdminRoleAccess = await enableAdminRole(roleId);
    if (enableAdminRoleAccess.messageCode == 200) {
      notification.success({
        message: enableAdminRoleAccess.message,
      });
      setRemoveAdminModal(false);
      setIsAdmin("N");
    } else {
      notification.info({
        message: enableAdminRoleAccess.message,
      });
      setRemoveAdminModal(false);
    }
  };

  const makeAdminAccess = async () => {
    const activeAdminRoleAccess = await activeAdminRole(roleId);
    if (activeAdminRoleAccess.messageCode == 200) {
      notification.success({
        message: activeAdminRoleAccess.message,
      });
      setMakeAdminModal(false);
      setIsAdmin("Y");
    } else {
      notification.info({
        message: activeAdminRoleAccess.message,
      });
      setMakeAdminModal(false);
    }
  };

  const getUsersModal = async () => {
    setGetUsersFromRoleModal(true);
    const getUsersFromRoleData = await getUsersFromRole(roleId);
    setUsersBaseOnRolesData(getUsersFromRoleData);
  };

  return (
    <div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
        <Row>
          <Col {...responsiveDesignForColumn}>
            <img src={InvoiceLogo} alt="invoice" align="left" /> <p /* style={Themes.contentWindow.ListWindowHeader.listWindowTitle} */> &ensp;Role-Details</p>
          </Col>

          {updateButtonCondition == true ? (
            <Col {...responsiveDesignForColumn}>
              <Dropdown placement="bottomLeft" overlay={content}>
                <Button
                  type="default"
                  /*  onClick={() => {
                                         history.push(`/window/pages/7199`);
                                     }} */
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    height: "2rem",
                    width: "5.4rem",
                    border: "0.25px solid rgb(7, 136, 141)",
                    borderRadius: "2px",
                    opacity: 1,
                    fontWeight: 500,
                    margin: "0px 5px 0px 5px",
                    float: "right",
                    backgroundColor: "rgb(8, 158, 164)",
                    color: "white",
                  }}

                  //   style={Themes.contentWindow.ListWindowHeader.newButtonForlist}
                >
                  Action
                </Button>
              </Dropdown>
              <Button type="default" onClick={handleOk} htmlType="submit" /* style={Themes.contentWindow.ListWindowHeader.newButtonForlist} */>
                Update
              </Button>

              <Button
                type="default"
                onClick={() => {
                  history.push(`/window/pages/7199`);
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "14px",
                  height: "2rem",
                  width: "5.4rem",
                  border: "0.25px solid rgb(7, 136, 141)",
                  borderRadius: "2px",
                  opacity: 1,
                  fontWeight: 500,
                  margin: "0px 5px 0px 0px",
                  float: "right",
                }}

                //   style={Themes.contentWindow.ListWindowHeader.newButtonForlist}
              >
                Cancel
              </Button>
            </Col>
          ) : (
            <Col {...responsiveDesignForColumn}>
              <Dropdown placement="bottomLeft" overlay={content}>
                <Button
                  type="default"
                  /*  onClick={() => {
                                             history.push(`/window/pages/7199`);
                                         }} */
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    height: "2rem",
                    width: "5.4rem",
                    border: "0.25px solid rgb(7, 136, 141)",
                    borderRadius: "2px",
                    opacity: 1,
                    fontWeight: 500,
                    margin: "0px 5px 0px 5px",
                    float: "right",
                    backgroundColor: "rgb(8, 158, 164)",
                    color: "white",
                  }}

                  //   style={Themes.contentWindow.ListWindowHeader.newButtonForlist}
                >
                  Action
                </Button>
              </Dropdown>
            </Col>
          )}
        </Row>
        <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
          <Form layout="vertical" name="control-hooks" form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Role Name" name="roleName">
                  <Input placeholder="Role Name" />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Description" name="description">
                  <Input placeholder="Description" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Menu Id" name="menuId">
                  <Input placeholder="Menu Id" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <div /* style={Themes.contentWindow.recordWindow.RecordLines.mainDiv} */>
          <Row>
            <Col span={6} /* style={Themes.contentWindow.recordWindow.RecordLines.linesSearchPadding} */>
              <Input
                placeholder="Search"
                value={searchInput}
                // onChange={getSearchData}
                style={{ width: "85%", margin: "5px" }}
                suffix={<i className="fa fa-search" role="presentation" aria-hidden="true" /* style={Themes.contentWindow.recordWindow.RecordLines.linesSearchIcon} */ />}
              />
            </Col>
            <Col span={28}>
              <Button /* style={Themes.contentWindow.recordWindow.RecordLines.LinesAddNewButton} */ onClick={displayModuleAccess}>
                + Module Access
              </Button>
            </Col>
            <Col span={28}>
              <Button /* style={Themes.contentWindow.recordWindow.RecordLines.LinesAddNewButton} */ onClick={displayCopyRole}>
                + Copy roles
              </Button>
            </Col>
          </Row>
          <div>
            <Table
              rowClassName={() => "editable-row"}
              dataSource={lineRoleDetailsData}
              size="small"
              scroll={{ y: "55vh" }}
              columns={columnsForRoleDetails}
              bordered
              pagination={false}
            />
          </div>
        </div>

        <Modal
          visible={processModal}
          onCancel={handleCancel}
          width="25%"
          closable={false}
          // height="94%"
          style={{ top: "10px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={processModalClose}
                htmlType="submit"
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Submit</span>
              </Button>
              <Button
                key="back"
                onClick={processModalClose}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <Form layout="vertical" name="control-hooks" form={form} onFinish={onFinishForLineRole}>
                <Table dataSource={processData} columns={processColumns} pagination={false} />
              </Form>
            </Card>
          </Card>
        </Modal>
        <Modal
          visible={moduleAccessModal}
          onCancel={handleCancel}
          width="75%"
          height="80%"
          closable={false}
          // height="94%"
          style={{ top: "20px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={submitModuleAccess}
                htmlType="submit"
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Submit</span>
              </Button>
              <Button
                key="back"
                onClick={processModalClose}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <h3
              style={{
                fontWeight: "500",
                fontSize: "19px",
                color: "black",
                marginTop: "4px",
                marginLeft: "2px",
              }}
            >
              <span>Module Access</span>
            </h3>
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <Form layout="vertical" name="moduleAccess" form={form} onFinish={onFinishModuleAccess}>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Module" name="moduleAccess">
                      <Select
                        className="ant-select-enabled"
                        dropdownClassName="certain-category-search-dropdown"
                        placeholder="Select Module"
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 228 }}
                        //  onFocus={getBusinessUnit}
                        mode="multiple"
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: "100%" }}
                        onChange={onModuleChange}
                      >
                        {moduleMenudata}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Access To" name="moduleAccessTo">
                      <Select
                        className="ant-select-enabled"
                        dropdownClassName="certain-category-search-dropdown"
                        placeholder="Select Access To"
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 228 }}
                        //  onFocus={getBusinessUnit}
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: "100%" }}
                        onChange={selectAccessto}
                      >
                        {accessToArray.map((option, index) => (
                          <Option key={`${index}-${option.name}`} value={option.recordid}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="New Role Name" name="roleName">
                      <span style={{ fontWeight: "bold" }}>{roleName}</span>
                    </Form.Item>
                  </Col>
                </Row>
                <Table
                  style={{ height: "45vh", overflowY: "scroll" }}
                  // rowSelection={ModulerowSelection}
                  rowKey={(record) => record.id}
                  dataSource={moduleAccessdataSource}
                  columns={ModuleAccessColumns}
                  pagination={false}
                />
              </Form>
            </Card>
          </Card>
        </Modal>

        <Modal
          visible={copyRoleModal}
          onCancel={handleCancel}
          width="75%"
          height="80%"
          closable={false}
          // height="94%"
          style={{ top: "20px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={submitCopyRoles}
                htmlType="submit"
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Submit</span>
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <h3
              style={{
                fontWeight: "500",
                fontSize: "19px",
                color: "black",
                marginTop: "4px",
                marginLeft: "2px",
              }}
            >
              <span>Copy Role</span>
            </h3>
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <Form layout="vertical" name="copyRolesForm" form={form} onFinish={onFinishCopyRoles}>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Role" name="copyRole">
                      <Select
                        className="ant-select-enabled"
                        dropdownClassName="certain-category-search-dropdown"
                        placeholder="Select Access To"
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 228 }}
                        //  onFocus={getBusinessUnit}
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: "100%" }}
                        onChange={onRolesChange}
                      >
                        {copyDropDownRolesData.map((option, index) => (
                          <Option key={`${index}-${option.name}`} value={option.recordid}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="New Role Name" name="newRoleName">
                      <span style={{ fontWeight: "bold" }}>{roleName}</span>
                    </Form.Item>
                  </Col>
                </Row>
                <Table
                  style={{ height: "45vh", overflowY: "scroll" }}
                  // rowSelection={ModulerowSelection}
                  rowKey={(record) => record.id}
                  dataSource={copyRolesData}
                  columns={copyRoleColumns}
                  pagination={false}
                />
              </Form>
            </Card>
          </Card>
        </Modal>

        <Modal
          visible={processModalForModuleAccess}
          onCancel={handleCancel}
          width="25%"
          closable={false}
          // height="94%"
          style={{ top: "10px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={handleCancel}
                htmlType="submit"
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Confirm</span>
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <Form layout="vertical" name="control-hooks" form={form} onFinish={onFinishForLineRole}>
                <Table dataSource={processDataForModuleAccess} columns={processColumnsForModuleAccess} pagination={false} />
              </Form>
            </Card>
          </Card>
        </Modal>

        <Modal
          visible={copyRolesProcessModal}
          onCancel={handleCancel}
          width="25%"
          closable={false}
          // height="94%"
          style={{ top: "10px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={handleCancel}
                htmlType="submit"
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Confirm</span>
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <Form layout="vertical" name="control-hooks" form={form} onFinish={onFinishForLineRole}>
                <Table dataSource={copyRolesProcessData} columns={copyRoleprocessColumns} pagination={false} />
              </Form>
            </Card>
          </Card>
        </Modal>
        <Modal
          visible={removeAdminModal}
          onCancel={handleCancel}
          width="25%"
          closable={false}
          // height="94%"
          style={{ top: "10px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={removeAdminAccess}
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Confirm</span>
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}> Remove Admin Access</h3>
              <p style={{ textAlign: "center", fontSize: "12px", color: "#5d5454", alignContent: "center" }}>Are you sure want to remove Admin Access?</p>
              <p style={{ textAlign: "center", fontSize: "12px", color: "#5d5454", alignContent: "center" }}>Note: On confirm Admin Access will be removed permanently !</p>
            </Card>
          </Card>
        </Modal>

        <Modal
          visible={makeAdminModal}
          onCancel={handleCancel}
          width="25%"
          closable={false}
          // height="94%"
          style={{ top: "10px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={makeAdminAccess}
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Confirm</span>
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}> Admin Acces</h3>
              <p style={{ textAlign: "center", fontSize: "12px", color: "#5d5454", alignContent: "center" }}>Are you sure want to give Admin Access?</p>
              <p style={{ textAlign: "center", fontSize: "12px", color: "#5d5454", alignContent: "center" }}>Note: On confirm Admin Access will be given to all windows !</p>
            </Card>
          </Card>
        </Modal>

        <Modal
          visible={getUsersFromRoleModal}
          onCancel={handleCancel}
          width="25%"
          closable={false}
          // height="94%"
          style={{ top: "10px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={handleCancel}
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Ok</span>
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <h3
              style={{
                fontWeight: "500",
                fontSize: "19px",
                color: "black",
                marginTop: "4px",
                marginLeft: "2px",
              }}
            >
              <span>Users</span>
            </h3>
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <Form layout="vertical" name="control-hooks" form={form} onFinish={onFinishForLineRole}>
                <Table dataSource={usersBaseOnRolesData} columns={usersBaseOnRolesColumns} pagination={false} />
              </Form>
            </Card>
          </Card>
        </Modal>
      </Spin>
    </div>
  );
};

export default UserWindowHeader;
