import React, { useEffect, useRef, useState } from 'react';
import { select, arc, pie, scaleOrdinal } from "d3";

const useResizeObserver = ref => {
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach(entry => {
                setDimensions(entry.contentRect);
            })
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return dimensions;
};

const GaugeChartComponent=(props)=> {
    // console.log("===>GaugeChartComponent props<===",props);
    const data = props.gaugeChartdata;
    const properties = props.gaugeChartProperties;
    // console.log(properties);
    // console.log(data);

    let keys = [];
    let gaugeData = [];
    // console.log(Object.keys(data[0]));
    // console.log(Object.values(data[0]));
    if (data !== undefined && data.length > 0) {
        keys = Object.keys(data[0]);
        gaugeData = Object.values(data[0]);
    };
    gaugeData[1] = `${100-gaugeData[0]}`;
    keys[1] = 'other'
    // console.log(gaugeData);
    // console.log(keys);

    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    useEffect(() => {
        const svg = select(svgRef.current);

        if (!dimensions) return;

        const arcGenerator = arc()
            .innerRadius(dimensions.height / 5)
            .outerRadius(dimensions.height / 2.5);

        const pieGenerator = pie()
            .startAngle(0.5 * Math.PI)
            .endAngle(-0.5 * Math.PI);

        const instructions = pieGenerator(gaugeData);

        const colors = scaleOrdinal()
            .domain(keys)
            .range([properties.gaugeColor, "#eee"]);

        svg
            .selectAll(".slice")
            .data(instructions)
            .join("path")
            .attr("class", "slice")
            // .attr("stroke", "red")
            .attr("fill", (instruction, index) => { return colors(index)} )
            .style("transform", `translate(${dimensions.width / 2}px, ${dimensions.height / 2.5}px)`)
            .attr("d", instruction => arcGenerator(instruction))

    }, [dimensions, data, keys]);

    return (
        <div ref={wrapperRef} style={{
            height: props.fullScreenValue === true ? "75vh" : props.height === undefined || props.height === null ? "35vh" : `${props.height-5}vh`
        }}>
            <svg ref={svgRef} style={{
                background: `${properties.bgcolor}`,
                overflow: 'visible',
                display: 'block',
                width: '100%',
                height: props.fullScreenValue === true ? "75vh" : props.height === undefined || props.height === null ? "35vh" : `${props.height-5}vh`
            }}>
            </svg>
        </div>
    )
}

export default GaugeChartComponent
