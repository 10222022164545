import React, { useEffect, useRef, useState } from 'react';
import { select, arc, pie, scaleOrdinal } from "d3";

const useResizeObserver = ref => {
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach(entry => {
                setDimensions(entry.contentRect);
            })
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return dimensions;
};

const DonutChartComponent=(props)=> {
    // console.log("===>PieChartComponent props<===",props);
    const data = props.donutChartdata;
    // console.log(data);
    const properties = props.donutChartProperties;
    let finalData;
    if (data !== undefined && data.length > 0) {
        finalData = data[1];
    }
    // console.log(finalData);
    // console.log(properties);
    let array = [];
    if (finalData !== undefined && finalData.length > 0) {
        for (let index = 1; index < finalData[0].length; index++) {
            let obj = {};
            for (let index1 = 0; index1 < finalData.length; index1++) {
              obj[finalData[index1][0]] = finalData[index1][index];
            }
            array.push(obj);
        }
    };
    let keys = [];
    let donutData = [];
    if (finalData !== undefined && finalData.length > 0) {
        for (let index = 1; index < finalData.length; index++) {
            keys.push(finalData[index][0]);
            donutData.push(finalData[index][1]);
        } 
    };
    // console.log(array);
    // console.log(keys);

    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    useEffect(() => {
        const svg = select(svgRef.current);

        if (!dimensions) return;

        const arcGenerator = arc().innerRadius(dimensions.height / 5).outerRadius(dimensions.height / 2.5);

        const pieGenerator = pie();

        const instructions = pieGenerator(donutData);

        const colors = scaleOrdinal()
            .domain(keys)
            .range(JSON.parse(properties.donutcolor.replace(/'/g, '"')));

        svg 
            .selectAll(".sclice")
            .data(instructions)
            .join("path")
            .attr("class", "sclice")
            .attr("fill", (instruction, index) => { return colors(index)} )
            .style("transform", `translate(${dimensions.width / 2}px, ${dimensions.height / 2.5}px)`)
            .attr("d", instruction => arcGenerator(instruction));

    }, [array, dimensions, keys]);
    return (
        <div ref={wrapperRef} style={{
            height: props.fullScreenValue === true ? "75vh" : props.height === undefined || props.height === null ? "35vh" : `${props.height-5}vh`
        }}>
            <svg ref={svgRef} style={{
                background: `${properties.bgcolor}`,
                overflow: 'visible',
                display: 'block',
                width: '100%',
                height: props.fullScreenValue === true ? "75vh" : props.height === undefined || props.height === null ? "35vh" : `${props.height-5}vh`
            }}>
            </svg>
        </div>
    )
}

export default DonutChartComponent