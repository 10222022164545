import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";

import { Row, Col, Spin, Menu, Card, Divider } from "antd";
import UserIcon from "../../assets/images/blankImage.png";
import CloseIcon from "../../assets/images/closeIcon.png";
import MeghaPhone from "../../assets/images/megaphone.svg";
import GettingStarted from "../../assets/images/getting_started.svg";
import UserDocs from "../../assets/images/userdocs.svg";
import FAQ from "../../assets/images/faqs.svg";

const { SubMenu } = Menu;

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    setLoading(false);
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };
  const responsiveIcon = {
    xxl: 2,
    xl: 2,
    lg: 2,
    xs: 2,
    sm: 2,
    md: 2,
  };

  const responsivePhone = {
    xxl: 1,
    xl: 1,
    lg: 1,
    xs: 1,
    sm: 1,
    md: 1,
  };

  const responsiveText = {
    xxl: 13,
    xl: 13,
    lg: 13,
    xs: 13,
    sm: 13,
    md: 13,
  };

  const responsiveImages = {
    xxl: 8,
    xl: 8,
    lg: 8,
    xs: 8,
    sm: 8,
    md: 8,
  };

  const responsiveUser = {
    xxl: 10,
    xl: 10,
    lg: 10,
    xs: 10,
    sm: 10,
    md: 10,
  };

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 12,
    sm: 12,
    md: 12,
  };

  const responsiveSearch = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 24,
    sm: 24,
    md: 24,
  };

  return (
    <Spin spinning={loading} tip="Loading..." style={{ position: "relative", top: "25em" }} size="large">
      <Scrollbars
        style={{
          height: "90vh",
        }}
        autoHide
        // Hide delay in ms
        autoHideTimeout={1000}
        // Duration for hide animation in ms.
        autoHideDuration={200}
        thumbSize={90}
        renderView={renderView}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
      >
        <Card style={{ backgroundColor: "rgb(245, 245, 245)", border: "0px solid #f2f2f2" }}>
          <Row>
            <Col {...responsiveIcon}>
              <img src={UserIcon} style={{ width: "62px", height: "62px", borderRadius: "37px" }} alt="UserIcon" />
            </Col>
            <Col {...responsiveUser}>
              <Row>
                <Col {...responsiveSearch}>
                  <h5 style={{ fontSize: "24px", fontWeight: "600", marginBottom: "0px" }}>Welcome CW Solutions</h5>
                </Col>
                <Col {...responsiveSearch}>
                  <h6 style={{ color: "#4E4E4E", fontSize: "12px" }}>Logged in as CW Admin</h6>
                </Col>{" "}
              </Row>
            </Col>
            <Col {...responsiveDesignForColumn}>
              <h5
                style={{
                  fontSize: "15px",
                  color: "#939398",
                  float: "right",
                  marginBottom: "0px",
                }}
              >
                <span>20 Oct 21,12:35 PM</span>
              </h5>
            </Col>
          </Row>{" "}
          <br />
          <Row>
            <Col {...responsiveSearch}>
              <div className="card" style={{ marginBottom: "5px", borderLeft: "5px solid #079DA3", backgroundColor: "#fff", borderRadius: "6px" }}>
                <div className="card-body" style={{ padding: "8px" }}>
                  <Row>
                    <Col {...responsivePhone}>
                      {" "}
                      <img src={MeghaPhone} alt="MeghaPhone" style={{ height: "32px", width: "32px" }} />{" "}
                    </Col>
                    <Col {...responsiveText}>
                      <h5
                        style={{
                          marginBottom: "3px",
                          color: "#010113",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Income Tax Document Submission Last Date
                      </h5>
                      <Col {...responsiveSearch}>
                        <p style={{ marginLeft: "5px", color: "#161417" }}>Please suggest Income Tax Proof Documents by December 31st 2020</p>
                      </Col>
                    </Col>
                    <Col {...responsiveUser} style={{ textAlign: "right", paddingRight: "10px" }}>
                      <img role="presentation" style={{ height: "12px", margin: "0%", marginTop: "0.6rem" }} src={CloseIcon} alt="close" />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveSearch}>
              <div className="card" style={{ marginBottom: "5px", borderLeft: "5px solid #079DA3", backgroundColor: "#fff", borderRadius: "6px" }}>
                <div className="card-body" style={{ padding: "8px" }}>
                  <Row>
                    <Col {...responsivePhone}>
                      {" "}
                      <img src={MeghaPhone} alt="MeghaPhone" style={{ height: "32px", width: "32px" }} />{" "}
                    </Col>
                    <Col {...responsiveText}>
                      <h5
                        style={{
                          marginBottom: "3px",
                          color: "#010113",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Income Tax Document Submission Last Date
                      </h5>
                      <Col {...responsiveSearch}>
                        <p style={{ marginLeft: "5px", color: "#161417" }}>Please suggest Income Tax Proof Documents by December 31st 2020</p>
                      </Col>
                    </Col>
                    <Col {...responsiveUser} style={{ textAlign: "right", paddingRight: "10px" }}>
                      <img role="presentation" style={{ height: "12px", margin: "0%", marginTop: "0.6rem" }} src={CloseIcon} alt="close" />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveSearch}>
              <div className="card" style={{ marginBottom: "5px", borderLeft: "5px solid #079DA3", backgroundColor: "#fff", borderRadius: "6px" }}>
                <div className="card-body" style={{ padding: "8px" }}>
                  <Row>
                    <Col {...responsivePhone}>
                      {" "}
                      <img src={MeghaPhone} alt="MeghaPhone" style={{ height: "32px", width: "32px" }} />{" "}
                    </Col>
                    <Col {...responsiveText}>
                      <h5
                        style={{
                          marginBottom: "3px",
                          color: "#010113",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Income Tax Document Submission Last Date
                      </h5>
                      <Col {...responsiveSearch}>
                        <p style={{ marginLeft: "5px", color: "#161417" }}>Please suggest Income Tax Proof Documents by December 31st 2020</p>
                      </Col>
                    </Col>
                    <Col {...responsiveUser} style={{ textAlign: "right", paddingRight: "10px" }}>
                      <img role="presentation" style={{ height: "12px", margin: "0%", marginTop: "0.6rem" }} src={CloseIcon} alt="close" />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: "3px" }}>
            <Col {...responsiveDesignForColumn}>
              <Card style={{ borderRadius: "6px", marginRight: "8px", height: "326px" }}>
                <h5 style={{ fontWeight: "bold", fontSize: "14px", padding: "5px" }}>Quick Links</h5>
                <Divider style={{ margin: "-1px", borderTop: "1px solid #e5e5ff" }} />
                <Menu style={{ width: "100%", border: "none", padding: "10px", paddingTop: "0px" }} mode="inline">
                  <SubMenu key="sub2" title="Stock Transfers">
                    <Menu.Item key="5">Stock Transfers</Menu.Item>
                    <Menu.Item key="6">Stock Transfers</Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub2" title="Stock Transfers">
                    <Menu.Item key="5">Stock Transfers</Menu.Item>
                    <Menu.Item key="6">Stock Transfers</Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub2" title="Stock Transfers">
                    <Menu.Item key="5">Stock Transfers</Menu.Item>
                    <Menu.Item key="6">Stock Transfers</Menu.Item>
                  </SubMenu>
                </Menu>
              </Card>{" "}
            </Col>
            <Col {...responsiveDesignForColumn}>
              <Card style={{ borderRadius: "6px", marginLeft: "8px", height: "326px" }}>
                <Row>
                  <Col {...responsiveDesignForColumn}>
                    <h5 style={{ fontWeight: "bold", fontSize: "14px", padding: "5px" }}>Tasks</h5>
                  </Col>
                  <Col {...responsiveDesignForColumn}>
                    <h2 style={{ float: "right", cursor: "pointer", marginBottom: "3px", fontWeight: "bold", paddingRight: "10px" }} role="presentation">
                      +
                    </h2>
                  </Col>
                </Row>
                <Divider style={{ margin: "-1px", borderTop: "1px solid #e5e5ff" }} />
                <Menu style={{ width: "100%", border: "none", padding: "10px", paddingTop: "0px" }} mode="inline">
                  <SubMenu key="sub2" title="Stock Transfers">
                    <Menu.Item key="5">Stock Transfers</Menu.Item>
                    <Menu.Item key="6">Stock Transfers</Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub2" title="Stock Transfers">
                    <Menu.Item key="5">Stock Transfers</Menu.Item>
                    <Menu.Item key="6">Stock Transfers</Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub2" title="Stock Transfers">
                    <Menu.Item key="5">Stock Transfers</Menu.Item>
                    <Menu.Item key="6">Stock Transfers</Menu.Item>
                  </SubMenu>
                </Menu>
              </Card>{" "}
            </Col>
          </Row>
          <Row style={{ paddingTop: "16px" }}>
            <Col {...responsiveDesignForColumn}>
              <Card style={{ borderRadius: "6px", marginRight: "8px", height: "326px" }}>
                <Row>
                  <Col {...responsiveDesignForColumn}>
                    <h5 style={{ fontWeight: "bold", fontSize: "14px", padding: "5px" }}>Help & Support</h5>
                  </Col>
                  <Col {...responsiveDesignForColumn} style={{ textAlign: "right", paddingRight: "10px" }}>
                    <i className="fa fa-search" style={{ color: "#BABDC1" }} />
                  </Col>
                </Row>{" "}
                <Divider style={{ margin: "-1px", borderTop: "1px solid #e5e5ff" }} />
                <Row>
                  <Col {...responsiveImages}>
                    <Card style={{ borderRadius: "6px", border: "1px solid #e4e9f0", margin: "10px" }}>
                      {" "}
                      <Row>
                        <Col {...responsiveSearch} style={{ textAlign: "center" }}>
                          <img src={GettingStarted} alt="gs" style={{ height: "50px" }} />
                        </Col>
                        <Col {...responsiveSearch} style={{ textAlign: "center" }}>
                          <p />
                          <label style={{ color: "#161417" }}>
                            <a style={{ color: "#161417" }} target="_blank" href="https://cw.solutions/" rel="noopener noreferrer">
                              Getting Started
                            </a>
                          </label>
                        </Col>
                      </Row>
                    </Card>{" "}
                  </Col>
                  <Col {...responsiveImages}>
                    <Card style={{ borderRadius: "6px", border: "1px solid #e4e9f0", margin: "10px" }}>
                      {" "}
                      <Row>
                        <Col {...responsiveSearch} style={{ textAlign: "center" }}>
                          <img src={UserDocs} alt="gs" style={{ height: "50px" }} />
                        </Col>
                        <Col {...responsiveSearch} style={{ textAlign: "center" }}>
                          <p />
                          <label style={{ color: "#161417" }}>
                            <a style={{ color: "#161417" }} target="_blank" href="https://cw.solutions/" rel="noopener noreferrer">
                              User Docs{" "}
                            </a>
                          </label>
                        </Col>
                      </Row>
                    </Card>{" "}
                  </Col>
                  <Col {...responsiveImages}>
                    <Card style={{ borderRadius: "6px", border: "1px solid #e4e9f0", margin: "10px" }}>
                      {" "}
                      <Row>
                        <Col {...responsiveSearch} style={{ textAlign: "center" }}>
                          <img src={FAQ} alt="gs" style={{ height: "50px" }} />
                        </Col>
                        <Col {...responsiveSearch} style={{ textAlign: "center" }}>
                          <p />
                          <label style={{ color: "#161417" }}>
                            <a style={{ color: "#161417" }} target="_blank" href="https://cw.solutions/" rel="noopener noreferrer">
                              FAQ{" "}
                            </a>
                          </label>
                        </Col>
                      </Row>
                    </Card>{" "}
                  </Col>
                </Row>
              </Card>{" "}
            </Col>
            <Col {...responsiveDesignForColumn}>
              <Card style={{ borderRadius: "6px", marginLeft: "8px" }}>
                <Row>
                  <Col {...responsiveDesignForColumn}>
                    <h5 style={{ fontWeight: "bold", fontSize: "14px", padding: "5px" }}>Alerts</h5>
                  </Col>
                </Row>
                <Divider style={{ margin: "-1px", borderTop: "1px solid #e5e5ff" }} />
                <Row style={{ marginTop: "10px" }}>
                  <Col {...responsiveSearch}>
                    <Card
                      style={{
                        background: "#FBFBFB",
                        borderLeft: "3px solid #00FF7F",
                        borderRadius: "4px",
                        marginBottom: "0px",
                      }}
                    >
                      <Row>
                        <p style={{ color: "#19181A", marginBottom: "2px" }}>Stock Receipt Alert</p>
                      </Row>
                      <Row>
                        <p style={{ color: "#19181A", marginBottom: "0px" }}>GRN Document No - JBGRN-20244</p>
                      </Row>
                    </Card>{" "}
                  </Col>
                  <Col {...responsiveSearch} style={{ paddingTop: "5px" }}>
                    <Card
                      style={{
                        background: "#FBFBFB",
                        borderLeft: "3px solid #00FF7F",
                        borderRadius: "4px",
                        marginBottom: "0px",
                      }}
                    >
                      <Row>
                        <p style={{ color: "#19181A", marginBottom: "2px" }}>Stock Receipt Alert</p>
                      </Row>
                      <Row>
                        <p style={{ color: "#19181A", marginBottom: "0px" }}>GRN Document No - JBGRN-20244</p>
                      </Row>
                    </Card>{" "}
                  </Col>
                  <Col {...responsiveSearch} style={{ paddingTop: "5px" }}>
                    <Card
                      style={{
                        background: "#FBFBFB",
                        borderLeft: "3px solid #00FF7F",
                        borderRadius: "4px",
                        marginBottom: "0px",
                      }}
                    >
                      <Row>
                        <p style={{ color: "#19181A", marginBottom: "2px" }}>Stock Receipt Alert</p>
                      </Row>
                      <Row>
                        <p style={{ color: "#19181A", marginBottom: "0px" }}>GRN Document No - JBGRN-20244</p>
                      </Row>
                    </Card>{" "}
                  </Col>
                  <Col {...responsiveSearch} style={{ paddingTop: "5px" }}>
                    <Card
                      style={{
                        background: "#FBFBFB",
                        borderLeft: "3px solid #00FF7F",
                        borderRadius: "4px",
                        marginBottom: "0px",
                      }}
                    >
                      <Row>
                        <p style={{ color: "#19181A", marginBottom: "2px" }}>Stock Receipt Alert</p>
                      </Row>
                      <Row>
                        <p style={{ color: "#19181A", marginBottom: "0px" }}>GRN Document No - JBGRN-20244</p>
                      </Row>
                    </Card>{" "}
                  </Col>
                </Row>
              </Card>{" "}
            </Col>
          </Row>
        </Card>{" "}
      </Scrollbars>
    </Spin>
  );
};

export default HomePage;
