import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { notification, Spin, Modal, Table, Row, Col, Button, AutoComplete, Input, Card, Form, Select, Checkbox, Tabs } from "antd";
import { useWindowContext, useGlobalContext } from "../../../lib/storage";
import {
  getUserRoleAccessTab,
  saveNewUser,
  getUserAccess,
  getUsersBusinessUnit,
  getUsersHomeDashboard,
  getUsersHomeWindow,
  getUsersHomeReport,
  getUsersDefaultRole,
  getUsersDefaultBusinessUnit,
} from "../../../services/generic";
import { LoadingOutlined } from "@ant-design/icons";
import InvoiceLogo from "../../../assets/images/invoice.svg";
import "antd/dist/antd.css";

const UserWindowHeader = (props) => {
  const { setSearchKey, setLastRefreshed } = props;
  const history = useHistory();
  const { globalStore } = useGlobalContext();
  let usersData = JSON.parse(localStorage.getItem("userData"));
  // const Themes = JSON.parse(globalStore.userData.CW360_V2_UI);

  const [searchInput, setSearchInput] = useState("");

  const [usersBusinessUnitDropdown, setUsersBusinessUnitDropdown] = useState([]);
  const [usersHomeDashboardDropdown, setUsersHomeDashboardDropdown] = useState([]);
  const [loading, setLoading] = useState(false);

  const [usersHomeWindowDropdown, setUsersHomeWindowDropdown] = useState([]);
  const [usersHomeReportDropdown, setUsersHomeReportDropdown] = useState([]);

  const [usersDefaultRoleDropdown, setUsersDefaultRoleDropdown] = useState([]);
  const [usersDefaultBusinessUnitDropdown, setUsersDefaultBusinessUnitDropdown] = useState([]);

  const [lineBusinessUnitData, setLineBusinessUnitData] = useState([]);
  const [lineRolesData, setLineRolesData] = useState([]);

  const [buModalVisible, setBuModalVisible] = useState(false);
  const [roleModalVisible, setRoleModalVisible] = useState(false);
  const [usersRoleAccessDropdown, setUsersRoleAccessDropdown] = useState([]);

  const [autoGeneratePassFlag, setAutoGeneratePassFlag] = useState(true);

  const [isCreatorCheck, setIsCreatorCheck] = useState(false);

  const [userLineRoles, setUserLineRoles] = useState(false);

  const [headerFormData, setHeaderFormData] = useState("null");
  // const { windowStore , setWindowStore} = useWindowContext();
  // const selectedUserData = { ...windowStore.userRecordData };
  // const saveUserButton = { ...windowStore.saveUserButton };
  // const businessUnitData=selectedUserData['buAccess']
  // const roleAccessData=selectedUserData['roleAccess']
  const userId = localStorage.getItem("csUserId");
  // const saveUserButton=selectedUserData.saveUserButton

  const [form] = Form.useForm();
  const { TextArea, Password } = Input;
  const { Option } = Select;
  const [options, setOptions] = useState([]);
  const { TabPane } = Tabs;
  const moment = require("moment");

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 12,
    sm: 12,
    md: 12,
  };
  const responsiveButton = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 24,
    sm: 24,
    md: 12,
  };

  const responsiveSearch = {
    xxl: 6,
    xl: 6,
    lg: 6,
    xs: 24,
    sm: 12,
    md: 6,
  };

  useEffect(async () => {
    setLoading(true);

    const getUsersBusinessUnitData = await getUsersBusinessUnit();
    setUsersBusinessUnitDropdown(getUsersBusinessUnitData);

    const getUsersHomeDashboardData = await getUsersHomeDashboard();
    setUsersHomeDashboardDropdown(getUsersHomeDashboardData);

    const getUsersHomeWindowData = await getUsersHomeWindow();
    setUsersHomeWindowDropdown(getUsersHomeWindowData);

    const getUsersHomeReportData = await getUsersHomeReport();
    setUsersHomeReportDropdown(getUsersHomeReportData);

    const getUsersDefaultRoleData = await getUsersDefaultRole();
    setUsersDefaultRoleDropdown(getUsersDefaultRoleData);

    const getUsersDefaultBusinessUnitData = await getUsersDefaultBusinessUnit();
    setUsersDefaultBusinessUnitDropdown(getUsersDefaultBusinessUnitData);

    const getUserRoleAccessTabData = await getUserRoleAccessTab();
    setUsersRoleAccessDropdown(getUserRoleAccessTabData);

    if (userId === "undefined") {
    } else {
      const getUserAccessData = await getUserAccess(userId);
      form.setFieldsValue({
        firstname: getUserAccessData["firstname"],
        lastname: getUserAccessData["lastname"],
        username: getUserAccessData["username"],
        email: getUserAccessData["email"],
        description: getUserAccessData["description"],
        isadmin: getUserAccessData["isadmin"],
        bunitname: getUserAccessData["csBunitId"],
        home_dashboard_id: getUserAccessData["home_dashboard_id"],
        home_report_id: getUserAccessData["home_report_id"],
        home_window_id: getUserAccessData["home_window_id"],
      });

      setLineBusinessUnitData(getUserAccessData["buAccess"]);
      setLineRolesData(getUserAccessData["roleAccess"]);
    }
    setLoading(false);
  }, []);

  const onFinish = async (values) => {
    const now = moment().format("YYYY-MM-DD h:mm:ss");

    const roleAccessTosend = [];
    const bussinessAccessTosend = [];
    for (let index = 0; index < lineRolesData.length; index++) {
      const eleroleId = lineRolesData[index].roleId;
      const eleCsuserAccess = lineRolesData[index].csUserAccessId;
      const eleisCreator = lineRolesData[index].isCreator;
      roleAccessTosend.push({ roleId: eleroleId, csUserAccessId: eleCsuserAccess, isCreator: eleisCreator });
    }
    for (let index1 = 0; index1 < lineBusinessUnitData.length; index1++) {
      const eleroleId1 = lineBusinessUnitData[index1].bunitid;
      const eleCsbu = lineBusinessUnitData[index1].csuserbunitid;
      bussinessAccessTosend.push({ bunitid: eleroleId1, csuserbunitid: eleCsbu });
    }

    const obj = {
      csUserId: userId === "undefined" ? null : userId,
      created: now,
      createdby: usersData["user_id"],
      updated: now,
      updatedby: usersData["user_id"],
      isadmin: values["isactive"] === true || values["isactive"] === "Y" ? "Y" : "N",
      isactive: "Y",
      csClientId: usersData["cs_client_id"],
      csWindowId: null,
      description: values["description"],
      email: values["email"],
      firstname: values["firstname"],
      lastname: values["lastname"],
      name: null,
      username: values["username"],
      password: autoGeneratePassFlag === false ? values["password"] : null,
      csBunitId: values["bunitname"],
      default_cs_role_id: null,
      default_cs_bunit_id: null,

      home_dashboard_id: values["home_dashboard_id"],
      home_report_id: values["home_report_id"],
      home_window_id: values["home_window_id"],

      buAccess: bussinessAccessTosend,
      roleAccess: roleAccessTosend,
    };

    const newStrigifiedJson = JSON.stringify(JSON.stringify(obj));
    const saveNewUserDetails = await saveNewUser(newStrigifiedJson);

    if (saveNewUserDetails.messageCode == 200) {
      let userData = JSON.parse(saveNewUserDetails.data);

      localStorage.setItem("csUserId", userData["csUserId"]);
      notification.success({
        message: saveNewUserDetails.message,
      });
    } else {
      notification.info({
        message: saveNewUserDetails.message,
      });
    }
  };

  const columnsForBusinessUnit = [
    {
      width: 50,
      checkboxSelection(params) {
        return params.columnApi.getRowGroupColumns().length === 0;
      },
      headerCheckboxSelection(params) {
        return params.columnApi.getRowGroupColumns().length === 0;
      },
    },
    {
      title: "Business unit",
      dataIndex: "bunitname",
    },
  ];

  const columnsForRole = [
    {
      width: 50,
      checkboxSelection(params) {
        return params.columnApi.getRowGroupColumns().length === 0;
      },
      headerCheckboxSelection(params) {
        return params.columnApi.getRowGroupColumns().length === 0;
      },
    },
    {
      title: "Role",
      dataIndex: "roleName",
    },
    {
      title: "Creator",
      dataIndex: "isCreator",
    },
  ];

  const handleOk = () => {
    form.submit();
  };

  const onFinishForBusinessUnit = async (fieldsValue) => {
    const roleBusinessUnit = fieldsValue["roleBusinessUnit"];
    let selectedBU = usersBusinessUnitDropdown.find((o) => o.recordid === roleBusinessUnit);

    let values = [
      {
        bunitid: roleBusinessUnit,
        csuserbunitid: null,
        bunitname: selectedBU["name"],
      },
    ];

    var finalLineBusinessUnit = [...lineBusinessUnitData, ...values];
    setLineBusinessUnitData(finalLineBusinessUnit);
  };

  const onFinishForLineRole = async (fieldsValue) => {
    const roleForLine = fieldsValue["lineRole"];
    let selectedBU = usersRoleAccessDropdown.find((o) => o.recordid === roleForLine);

    let values = [
      {
        roleId: roleForLine,
        csUserAccessId: null,
        roleName: selectedBU["name"],
        isCreator: isCreatorCheck,
      },
    ];

    var finalLineBusinessUnit = [...lineRolesData, ...values];
    setLineRolesData(finalLineBusinessUnit);
  };

  const handleCancel = () => {
    setBuModalVisible(false);
    setRoleModalVisible(false);
  };

  const displayBusinessUnitLine = () => {
    // form.resetFields('roleBusinessUnit')
    setBuModalVisible(true);
  };

  const displayRoleLine = () => {
    // form.resetFields('roleBusinessUnit')
    setRoleModalVisible(true);
  };

  const config = {
    rules: [
      {
        // type: 'object',
        required: true,
        message: "Please select time!",
      },
    ],
  };

  /*   const getFieldValue = (fieldName) => {
      // alert(headerFormData[val])
         let values=headerFormData[fieldName]
            if(values==undefined){
                return "hello"
            }else{
                return values
            }
      }; */

  const onChangeAutogeneratePassFlag = (e) => {
    setAutoGeneratePassFlag(e.target.checked);
  };

  const isCreatoronChange = (e) => {
    // console.log("checked",e.target.checked)
    let value;
    if (e.target.checked === true) {
      value = "Y";
    } else {
      value = "N";
    }

    setIsCreatorCheck(value);
  };

  return (
    <div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
        <Row>
          <Col {...responsiveDesignForColumn}>
            <img src={InvoiceLogo} alt="invoice" align="left" /> <p /* style={Themes.contentWindow.ListWindowHeader.listWindowTitle} */> &ensp;User</p>
          </Col>

          <Col {...responsiveDesignForColumn}>
            <Button type="default" onClick={handleOk} htmlType="submit" /* style={Themes.contentWindow.ListWindowHeader.newButtonForlist} */>
              Save
            </Button>

            <Button
              type="default"
              onClick={() => {
                history.push(`/others/window/7198`);
              }}
              style={{
                cursor: "pointer",
                fontSize: "14px",
                height: "2rem",
                width: "5.4rem",
                border: "0.25px solid rgb(7, 136, 141)",
                borderRadius: "2px",
                opacity: 1,
                fontWeight: 500,
                margin: "0px 5px 0px 0px",
                float: "right",
              }}

              //   style={Themes.contentWindow.ListWindowHeader.newButtonForlist}
            >
              Cancel
            </Button>
          </Col>
        </Row>
        <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
          <Form layout="vertical" name="control-hooks" form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Business Unit" name="bunitname">
                  <Select
                    className="ant-select-enabled"
                    dropdownClassName="certain-category-search-dropdown"
                    placeholder="Business Unit"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 228 }}
                    //  onFocus={getBusinessUnit}
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: "100%" }}
                    // onChange={this.BuintOnchange}
                  >
                    {usersBusinessUnitDropdown.map((option, index) => (
                      <Option key={`${index}-${option.name}`} value={option.recordid}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="First Name" name="firstname">
                  <Input placeholder="First name" />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Last Name" name="lastname">
                  <Input placeholder="Last name" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Username" name="username">
                  <Input placeholder="User Name" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Admin" name="isactive">
                  <Checkbox
                    // checked={selectedUserData['isactive']}
                    // onChange={this.onChangeAdmin}
                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                  >
                    Admin
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Home Dashboard" name="home_dashboard_id">
                  <Select
                    className="ant-select-enabled"
                    placeholder="Home Report"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 228 }}
                    allowClear={true}
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: "100%" }}
                    // onFocus={this.getHomeReportData}
                    // onChange={this.homeReportOnchange}
                  >
                    {usersHomeDashboardDropdown.map((option, index) => (
                      <Option key={`${index}-${option.name}`} value={option.recordid}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Home Window" name="home_window_id">
                  <Select
                    className="ant-select-enabled"
                    placeholder="Home Window"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 228 }}
                    allowClear={true}
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: "100%" }}
                    // onFocus={this.getHomeReportData}
                    // onChange={this.homeReportOnchange}
                  >
                    {usersHomeWindowDropdown.map((option, index) => (
                      <Option key={`${index}-${option.name}`} value={option.recordid}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Home Report" name="home_report_id">
                  <Select
                    className="ant-select-enabled"
                    placeholder="Home Report"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 228 }}
                    allowClear={true}
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: "100%" }}
                    // onFocus={this.getHomeReportData}
                    // onChange={this.homeReportOnchange}
                  >
                    {usersHomeReportDropdown.map((option, index) => (
                      <Option key={`${index}-${option.name}`} value={option.recordid}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {userId === "undefined" ? (
                <Col span={6}>
                  <Form.Item>
                    <Checkbox checked={autoGeneratePassFlag} onChange={onChangeAutogeneratePassFlag} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}>
                      Auto Generate password
                    </Checkbox>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6}>
                  <Form.Item label="Default Role" name="defaultrole">
                    <Select
                      className="ant-select-enabled"
                      placeholder="Default role"
                      dropdownClassName="certain-category-search-dropdown"
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ width: 228 }}
                      showSearch
                      allowClear={true}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: "100%" }}
                      // onChange={this.defaultRoleOnchange}
                    >
                      {usersDefaultRoleDropdown.map((option, index) => (
                        <Option key={`${index}-${option.name}`} value={option.recordid}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {autoGeneratePassFlag == false ? (
                <Col span={6}>
                  <Form.Item label="Password" name="password">
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}

              {userId === "undefined" ? (
                ""
              ) : (
                <Col span={6}>
                  <Form.Item label="Default Business Unit" name="defaultbunit">
                    <Select
                      className="ant-select-enabled"
                      placeholder="Default Business Unit"
                      dropdownClassName="certain-category-search-dropdown"
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ width: 228 }}
                      showSearch
                      allowClear={true}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: "100%" }}
                      // onChange={this.defaultBunitOnchange}
                    >
                      {usersDefaultBusinessUnitDropdown.map((option, index) => (
                        <Option key={`${index}-${option.name}`} value={option.recordid}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col span={6}>
                <Form.Item label="Email" name="email">
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Description" name="description">
                  <TextArea placeholder="Description" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <div /* style={Themes.contentWindow.recordWindow.RecordLines.mainDiv} */>
          <Row>
            <Col span={24}>
              <Tabs
                // tabBarStyle={Themes.contentWindow.recordWindow.RecordLines.TabStyle}
                type="card"
                defaultActiveKey="0"
                // style={{ backgroundColor: '#F2F3F6' }}
              >
                <TabPane tab="Business Unit" key="1">
                  <Row>
                    <Col span={6} /* style={Themes.contentWindow.recordWindow.RecordLines.linesSearchPadding} */>
                      <Input
                        placeholder="Search"
                        value={searchInput}
                        // onChange={getSearchData}
                        style={{ width: "85%" }}
                        suffix={<i className="fa fa-search" role="presentation" aria-hidden="true" /* style={Themes.contentWindow.recordWindow.RecordLines.linesSearchIcon} */ />}
                      />
                    </Col>
                    <Col span={28}>
                      <Button /* style={Themes.contentWindow.recordWindow.RecordLines.LinesAddNewButton} */ onClick={displayBusinessUnitLine}>
                        +Add New
                      </Button>
                    </Col>
                  </Row>
                  <div>
                    <Table
                      /* style={Themes.contentWindow.recordWindow.RecordLines.linesTable} */
                      size="small"
                      scroll={{ y: "20vh" }}
                      pagination={false}
                      columns={columnsForBusinessUnit}
                      dataSource={lineBusinessUnitData}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Role" key="2">
                  <Row>
                    <Col span={6} /* style={Themes.contentWindow.recordWindow.RecordLines.linesSearchPadding} */>
                      <Input
                        placeholder="Search"
                        value={searchInput}
                        // onChange={getSearchData}
                        style={{ width: "85%" }}
                        suffix={<i className="fa fa-search" role="presentation" aria-hidden="true" /* style={Themes.contentWindow.recordWindow.RecordLines.linesSearchIcon} */ />}
                      />
                    </Col>
                    <Col span={28}>
                      <Button /* style={Themes.contentWindow.recordWindow.RecordLines.LinesAddNewButton} onClick={displayRoleLine} */>
                        +Add New
                      </Button>
                    </Col>
                  </Row>
                  <div>
                    <Table
                      /* style={Themes.contentWindow.recordWindow.RecordLines.linesTable} */
                      size="small"
                      scroll={{ y: "20vh" }}
                      pagination={false}
                      columns={columnsForRole}
                      dataSource={lineRolesData}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
        <Modal
          visible={buModalVisible}
          onCancel={handleCancel}
          width="25%"
          // height="94%"
          style={{ top: "10px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={handleOk}
                htmlType="submit"
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Submit</span>
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <h3
              style={{
                fontWeight: "500",
                fontSize: "19px",
                color: "black",
                marginTop: "4px",
                marginLeft: "2px",
              }}
            >
              <span>Add Business Unit</span>
            </h3>

            <br />
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <Form layout="vertical" name="control-hooks" form={form} onFinish={onFinishForBusinessUnit}>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Business Unit" name="roleBusinessUnit">
                      <Select
                        className="ant-select-enabled"
                        dropdownClassName="certain-category-search-dropdown"
                        placeholder="Business Unit"
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 228 }}
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: "100%" }}
                        // onChange={this.BuintOnchange}
                      >
                        {usersBusinessUnitDropdown.map((option, index) => (
                          <Option key={`${index}-${option.name}`} value={option.recordid}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Card>
        </Modal>
        <Modal
          visible={roleModalVisible}
          onCancel={handleCancel}
          width="25%"
          // height="94%"
          style={{ top: "10px" }}
          centered
          bodyStyle={{ padding: "0px" }}
          footer={[
            <div>
              <Button
                // className={`${styles.buttonStylesforlistProduct}`}
                onClick={handleOk}
                htmlType="submit"
                style={{
                  backgroundColor: "#089ea4",
                  color: "#fff",
                  border: "0.5px",
                  fontSize: "12px",
                  fontWeight: "700",
                  height: "35px",
                  width: "105px",
                  borderRadius: "2px",
                }}
              >
                <span>Submit</span>
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ececec",
                  border: "none",
                  color: "dimgray",
                  fontWeight: 600,
                }}
                // className={`${styles.buttonStylesforlistProductCancel}`}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Card style={{ backgroundColor: "#ececec" }}>
            <h3
              style={{
                fontWeight: "500",
                fontSize: "19px",
                color: "black",
                marginTop: "4px",
                marginLeft: "2px",
              }}
            >
              <span>Add Role</span>
            </h3>

            <br />
            <Card /* style={Themes.contentWindow.recordWindow.RecordHeader.headerCard} */>
              <Form layout="vertical" name="control-hooks" form={form} onFinish={onFinishForLineRole}>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Role" name="lineRole">
                      <Select
                        className="ant-select-enabled"
                        dropdownClassName="certain-category-search-dropdown"
                        placeholder="Role"
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 228 }}
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: "100%" }}
                        // onChange={this.BuintOnchange}
                      >
                        {usersRoleAccessDropdown.map((option, index) => (
                          <Option key={`${index}-${option.name}`} value={option.recordid}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Creator" name="creator">
                      <Checkbox checked={isCreatorCheck === "N" ? false : true} onChange={isCreatoronChange} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Card>
        </Modal>
      </Spin>
    </div>
  );
};

export default UserWindowHeader;
