import axios from "axios";
import { serverUrl } from "../constants/serverConfig";

let localToken;
const customInstance = axios.create();
customInstance.defaults.baseURL = serverUrl;
customInstance.defaults.method = "POST";
customInstance.defaults.headers.post["Content-Type"] = "application/json";


customInstance.interceptors.request.use(
    (config) => {
      if (!localToken) {
        localToken = JSON.parse(localStorage.getItem("authTokens"));
      }
      config.headers.Authorization = `${localToken.token_type} ${localToken.access_token}`;
      return config;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  customInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  );
  
  const getDeliveryLocation = async (e) =>{
    try{
      const deliveryData = await customInstance({
        data:{
          query:`query {
            getDeliveryLocations (bUnitId : "${e}")
        }`
        }
      })
      return JSON.parse(deliveryData.data.data.getDeliveryLocations)
    }catch (error) {
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

const getSupplierProduct = async (supplierId,bunitId)=>{
  try{
    const supplierProductData = await customInstance({
      data:{
        query: `query {
          getSupplierProduct(supplierId : "${supplierId}",bUnitId : "${bunitId}") {
            supplierProductId
            clientId
            clientName
            bUnitId
            bUnitName
            supplierId
            supplierName
            productId
            productName
            productCode
            qtyOnHand
            uomId
            uomName
            productCategoryId
            productcategoryName
            taxCategoryId
            taxCategoryName
            taxId
            taxName
            taxRate
            priceStd
            priceList
            twoWeekSale
            fourWeekSale
            upc
            description
            istaxincluded
            alternateUomList 
                { alternateUomId uomId uomName }
            margin
        }
      }`
      }
    })
    const data = supplierProductData.data.data.getSupplierProduct
    const tempArray = []
      for (let index = 0; index < data.length; index++) {
        const obj = {
          productCategoryName: data[index].productcategoryName,
          name: data[index].productName,
          description: data[index].description,
          twoWeekSale: data[index].twoWeekSale,
          qtyOnHand: data[index].qtyOnHand,
          productId: data[index].productId,
          key:data[index].value,
          uomName: data[index].uomName,
          uomId: data[index].uomId,
          priceList: data[index].priceList,
          priceStd: data[index].priceStd,
          unitPrice: data[index].priceStd,
          netUnitPrice:data[index].priceStd,
          unitPrice1: data[index].priceStd,
          priceList1: data[index].priceList,
          priceStd1: data[index].priceStd,
          priceStd2: data[index].priceStd,
          taxId: data[index].taxId,
          value: data[index].productCode,
          responseMargin:data[index].margin,
          upc :data[index].upc,
          taxName:data[index].taxName,
          taxRate:data[index].taxRate,
        }
        tempArray.push(obj)
      }
    return tempArray
  }catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
}

const getLandedCostData = async () =>{
  try{
    const deliveryData = await customInstance({
      data:{
        query: `query{
          getLctype{
              pLcTypeId
              key
              name
              product{
                  mProductId
                  name
              }
              csTaxId
              calcMethod
          }
      }`
      }
    })
    return deliveryData.data.data.getLctype
  }catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
}

  export{
    getDeliveryLocation,
    getSupplierProduct,
    getLandedCostData
  };